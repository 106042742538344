<template>
    <div>
        <div class="input-container">
            <el-input placeholder="请输入内容，例如 gamma-aminobutyric acid " v-model="input3" class="input-with-select">
                <el-select v-model="select" slot="prepend" style="width: 150px;" placeholder="请选择">
                    <el-option label="酶信息" value="酶信息"></el-option>
                    <el-option label="底物信息" value="底物信息"></el-option>
                    <el-option label="reaxys信息" value="reaxys信息"></el-option>
                </el-select>
                <el-button v-loading.fullscreen.lock="fullscreenLoading" slot="append" icon="el-icon-search"
                    @click="getdata"></el-button>
            </el-input>
            <div class="radio-group">
                <el-radio v-model="query" label="完全匹配" :disabled="disabledInput">完全匹配</el-radio>
                <!-- <el-radio v-model="query" label="模糊匹配" :disabled="disabledInput">模糊匹配</el-radio> -->
                <el-radio v-model="query" label="更快更准但是结果较少的模糊匹配" :disabled="disabledInput">模糊匹配</el-radio>
            </div>

        </div>



        <h2 style="text-align: center; margin-left: auto; margin-right: auto;">常见的酶</h2>
        <div class="cards-container" style="margin-top: 15px;">
            <div v-for="i in cardData" :key="i.img">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span style="font-size: 28px;">
                            <strong>
                                {{ i['enzyme_type'] }}
                            </strong>
                        </span>
                        <br>
                        <span> {{ i['enzyme_explain'] }}</span>

                    </div>

                    <div>
                        {{ i['ch_enzyme_explain'] }}
                    </div>
                    <div style="width: 350px;height: 70px;">
                        <img :src="i['img']" alt="" @click="openImage(i['img'])">
                    </div>
                    <el-button style="float: right; padding: 3px 0" type="text"
                        @click="toDigitaLibrary(i)">跳转</el-button>
                </el-card>
            </div>
        </div>
        <br>

    </div>

</template>


<script>
// 导入 axios
// import axios from 'axios';

export default {

    data() {
        return {
            select: '',
            input3: '',
            cardData: [],
            query: '完全匹配', // 完全匹配 或 模糊匹配
            fullscreenLoading: false,
            disabledInput: false
        }
    },
    created() {
        this.$axios.get('/EnzymeDB/EnzymeClass',
        ).then((res) => {
            if (res.data.status != '200') {
                this.$message.error(res.data.msg);
                return;
            }
            this.$message.success('获取数据成功');
            this.cardData = res.data.data;
        })
    },
    watch: {
        // 监控  select 的变化，如果select = rexays信息，则禁用输入框
        select: function (newVal) {
            if (newVal == 'reaxys信息') {
                this.disabledInput = true;
            } else {
                this.disabledInput = false;
            }
        }
    },

    methods: {

        openImage(imageUrl) {
            // console.log(this.query);
            window.open(imageUrl, '_blank'); // 使用 _blank 在新窗口中打开链接
        },

        handleSelectChange() {
            console.log(this.select);
        },
        getdata() {
            console.log(this.input3, this.select, this.query);
            if (this.input3 == '' && this.select != 'reaxys信息') {
                this.$message.error('请输入搜索内容');
                return;
            }
            if (this.select == '') {
                this.$message.error('请选择搜索类型');
                return;
            }
            if (this.query == '') {
                this.$message.error('请选择匹配方式');
                return;
            }
            if (this.query == "更快更准但是结果较少的模糊匹配" && this.select == '底物信息') {
                // this.query = '完全匹配';
                // this.$message.error('底物信息不支持模糊匹配');
                // return;
                console.log('模糊匹配');
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading, please wait',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            switch (this.query) {
                case "完全匹配":


                    if (this.select == '酶信息') {
                        // 酶信息完全匹配数据过多，直接跳转到数字化酶库页面
                        let keyword = this.input3;

                        this.$nextTick(() => {
                            this.$router.push({ name: '数字化酶库', params: { keyword: keyword } });
                            loading.close();
                        });
                    }
                    else if (this.select == '底物信息') {


                        this.$axios.post('/CompoundDB/CompoundSearchEnzyme', {
                            value: this.input3
                        }
                        ).then((res) => {
                            // 用户输入的关键词
                            let keyword = this.input3;
                            // console.log(1);
                            let data0 = res.data.data;
                            if (data0 != undefined && data0.length != 0) {
                                loading.close();
                                // 跳转到真正的数字化酶库页面
                                this.$nextTick(() => {

                                    let Data = {
                                        name: this.input3,
                                        data: data0
                                    }

                                    console.log(11111);
                                    localStorage.setItem("Fuzzy", JSON.stringify(Data));
                                    this.$router.push({ name: '底物到三元关系', params: { keyword: keyword, result: data0, select: this.select, status: 1 }, query: { keyword: this.input3, status: 1 } });
                                });
                            }
                            else {
                                let data1 = res.data.systemData;
                                let data2 = res.data.synonymData;
                                let data3 = res.data.unknownData;



                                // console.log(data1, data2, data3);

                                // console.log(data1 == undefined, data2 == undefined, data3 == undefined);

                                if ((data1 === null || typeof data1 === 'undefined' || data1.length === 0) &&
                                    (data2 === null || typeof data2 === 'undefined' || data2.length === 0) &&
                                    (data3 === null || typeof data3 === 'undefined' || data3.length === 0)) {
                                    // console.log(1111);
                                    this.$message.error('没有找到相关数据');
                                    loading.close();
                                    return;
                                }

                                let data = {
                                    systemData: data1,
                                    synonymData: data2,
                                    unknownData: data3
                                }

                                // systemValue在datastaus=1或者3的时候出现
                                // synonymValue在datastus=2或者是3的时候出现
                                // unknownValue在datastatus=0的时候出现
                                let value;
                                switch (res.data.dataStatus) {
                                    case 0:
                                        value = res.data.unknownValue

                                        break;
                                    case 1:
                                        value = res.data.systemValue

                                        break;
                                    case 2:

                                        value = res.data.synonymValue
                                        break;
                                    case 3:
                                        value = res.data.systemValue + "|" + res.data.synonymValue
                                        break;

                                }

                                loading.close();

                                let Data = {
                                    name: this.input3,
                                    data: data
                                }

                                localStorage.setItem("Fuzzy", JSON.stringify(Data));

                                let encrypted = window.btoa(value);


                                // 跳转到真正的数字化酶库页面
                                this.$nextTick(() => {
                                    loading.close();
                                    this.$router.push({ name: '底物到三元关系', params: { keyword: keyword, result: data, select: this.select, status: 0 }, query: { keyword: this.input3, status: 0, value: encrypted, datastus: res.data.dataStatus } });

                                });
                            }
                        }).catch(() => {
                            this.$message.error('未知错误，请联系管理员');

                            loading.close();
                        })
                    } else if (this.select == "reaxys信息") {


                        console.log(this.input3);
                        if (this.input3 === '') {
                            let routeURL = this.$router.resolve({ name: 'ReaxysData' }).href;
                            // 每次进入reaxys数据页面时，都将页数设置为1
                            localStorage.setItem("reaxysPage", 1);
                            loading.close();
                            window.open(routeURL, '_blank');
                        } else {
                            let params = { value: this.input3, page: 1 };

                            let routeURL = this.$router.resolve({ name: 'ReaxysData', query: params }).href;
                            // 每次进入reaxys数据页面时，都将页数设置为1
                            localStorage.setItem("reaxysPage", 1);
                            loading.close();
                            window.open(routeURL, '_blank');
                        }

                    }
                    break;
                //#region    
                /*  
              case "模糊匹配":

                  this.$axios.post("/EnzymeDB/BlurEnzymeNameSearch"
                      , { value: this.input3 },
                      {
                          headers: {
                              token: localStorage.getItem('token')
                          }
                      }
                  ).then((res) => {
                      if (res.data.status != '200') {
                          this.$message.error(res.data.msg);
                          loading.close();
                          return;
                      }
                      this.paramsdata = res.data.data;
                      if (this.paramsdata == undefined) {
                          this.$message.error('没有找到相关数据');
                          loading.close();
                          return;
                      }
                      if (this.paramsdata.length == 0) {
                          this.$message.error('没有找到相关数据');
                          loading.close();
                          return;
                      }
                      // console.log(this.paramsdata);
                      loading.close();
                      this.$router.push({ name: 'FuzzySearchResult', params: { tableData: this.paramsdata, query: this.query } });

                  })
                  console.log('模糊匹配');
                  break;*/
                //#endregion
                case "更快更准但是结果较少的模糊匹配":
                    if (this.select == '酶信息') {
                        this.$axios.post("/EnzymeDB/BlurEnzymeNameSearchES"
                            , { value: this.input3 },
                            {
                                headers: {
                                    token: localStorage.getItem('token')
                                }
                            }
                        ).then((res) => {

                            if (res.data.status != '200') {
                                this.$message.error(res.data.msg);
                                loading.close();
                                return;
                            }
                            this.paramsdata = res.data.data;
                            if (this.paramsdata == undefined) {
                                this.$message.error('没有找到相关数据');
                                loading.close();
                                return;
                            }
                            if (this.paramsdata.length == 0) {
                                this.$message.error('没有找到相关数据');
                                loading.close();
                                return;

                            }
                            console.log(this.paramsdata);
                            loading.close();

                            this.$router.push({ name: 'FuzzySearchResult', params: { tableData: this.paramsdata, query: this.query } });

                        })
                    }
                    if (this.select == '底物信息') {
                        console.log('底物信息');
                        // axios.post("https://192.168.30.99:443/CompoundDB/CompoundSearchES",{
                        this.$axios.post("/CompoundDB/CompoundSearchES", {
                            value: this.input3
                        },
                            {
                                headers: {
                                    token: localStorage.getItem('token')
                                }
                            }).then(
                                (res) => {
                                    
                                    this.paramsdata = res.data.data;
                                    console.log(this.paramsdata);
                                    this.$router.push({ name: 'FuzzySearchResultCompoundDB', params: { tableData: this.paramsdata, query: this.query } });

                                }
                            )
                        loading.close();
                        return;
                    }

                    break;

            }

        },
        toDigitaLibrary(row) {
            this.$router.push({ name: '数字化酶库', query: { explain: row.enzyme_explain } });
        }

    }
};
</script>

<style scoped>
.cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* 三列，每列占用等宽 */
    gap: 20px;
    /* 卡片之间的间隔 */
    justify-content: center;
    /* 水平居中 */
    padding: 0 16.66%;
    /* 左右各预留出1/6的空白 */
}

.card-item {
    width: 100%;
    /* 卡片宽度占满列宽 */
}

.box-card {
    width: 100%;
    /* 卡片盒子宽度占满父元素宽度 */
    height: 100%;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.radio-group {
    align-self: flex-start;
    padding-left: calc(50% - 150px);
    /* 根据 el-select 的宽度调整 */
    margin-top: 10px;
}

.input-with-select {
    width: 40%;
}
</style>
<template>
    <div class="table-container">
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="EC" label="EC(点击跳转)" width="180">
                <template slot-scope="scope">
                    <div @click="handleECClick(scope.row)">
                        {{ scope.row.EC }}
                    </div>

                </template>
            </el-table-column>
            <el-table-column prop="name" label="Name" width="180"></el-table-column>
            <el-table-column prop="alternativeNamingDifferences" label="OtherName"></el-table-column>
        </el-table>
    </div>
</template>


<script>
export default {
    data() {
        return {
            tableData: []
        }
    },
    created() {

        if (this.$route.params.tableData != undefined) {
            this.tableData = this.$route.params.tableData;
            localStorage.setItem('tableData', JSON.stringify(this.tableData));

        }
        else {
            this.tableData = JSON.parse(localStorage.getItem('tableData'));


        }

    },
    methods: {
        handleECClick(row) {
            this.$message.info('正在获取数据');

            this.$axios.get('/EnzymeDB/digitalEnzymeLibrarySearch?value=' + row.EC+"&page=1").then((res) => {

                if (res.data.status != '200') {
                    this.$message.error(res.data.msg);
                    return;
                }
                if ( res.data.data.length == 0) {
                    this.$message.error('没有数据');
                    return;
                }
                this.$message.success('获取数据成功');
                this.$router.push({ name: '数字化酶库', params: { tableData: res.data.data, select: '酶信息' ,keyword :row.EC} });
            })
        }
    }

}
</script>
<style scoped>
.table-container {
    margin-top: 20px;
    /* 距离顶端的距离 */
    margin-left: auto;
    /* 自动计算左边距，与margin-right一起实现水平居中 */
    margin-right: auto;
    /* 自动计算右边距 */
    width: 80%;
    /* 容器宽度，这将让表格左右各有10%的空间，如果需要更大或更小的距离，可以调整这个百分比 */
}
</style>
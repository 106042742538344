<template>
    <div class="content">
        <!-- Search Section -->
        <div class="input-container" v-show="!show">
            <el-input class="input-with-select" type="text" v-model="searchQuery" @keyup.enter="performSearch"
                :placeholder="inputText" size="medium">
                <el-button slot="append" type="primary" icon="el-icon-search" @click="performSearch">搜索</el-button>
            </el-input>
        </div>

        <!-- Main Container -->
        <div class="container"  v-show="show">
            <div class="left-panel">
                <!-- CAS Number -->
                <div class="info-item">
                    <span class="info-title">CAS号:</span>
                    <span class="info-value">{{ compoundData && compoundData.cas ? compoundData.cas : '暂无数据....'
                        }}</span>
                </div>
                <!-- Compound Image -->
                <img :src="imgPath" alt="Compound Image" class="compound-image">
                <!-- Information Section -->
                <div class="info-section">
                    <!-- Compound Name -->
                    <div class="info-item">
                        <span class="info-title">化合物名称:</span>
                        <span class="info-value">{{ compoundData ? compoundData.name : '暂无数据....' }}</span>
                    </div>
                    <!-- SMILES -->
                    <div class="info-item">
                        <span class="info-title">线性结构式（smiles）:</span>
                        <span class="info-value">{{ compoundData ? compoundData.smiles : '暂无数据....' }}</span>
                    </div>
                    <!-- Molecular Formula -->
                    <div class="info-item">
                        <span class="info-title">分子式（Molecular Formula）:</span>
                        <span class="info-value"
                            v-html="compoundData ? formatMolecularFormula(compoundData.formula) : '暂无数据....'"></span>
                    </div>
                    <!-- Molecular Mass -->
                    <div class="info-item">
                        <span class="info-title">分子质量（Molecular Mass）:</span>
                        <span class="info-value">{{ compoundData ? compoundData.molecularWeight : '暂无数据....' }}</span>
                    </div>
                    <!-- Citation -->
                    <div class="info-item">
                        <span class="info-title">引用（Cite this Page）:</span>
                        <span class="info-value">暂无数据....</span>
                    </div>
                    <div class="info-item">
                        <span class="info-title">inChi:</span>
                        <span class="info-value">{{ compoundData ? compoundData.inChi : '暂无数据....' }}</span>
                    </div>
                    <div class="info-item">
                        <span class="info-title">inChiKey:</span>
                        <span class="info-value">{{ compoundData ? compoundData.inChiKey : '暂无数据....' }}</span>
                    </div>
                    <div class="info-item">
                        <span class="info-title">source:</span>
                        <span class="info-value">{{ compoundData ? compoundData.source : '暂无数据....' }}</span>
                    </div>

                </div>
            </div>
            <div class="right-panel">
                <div class="top-section">
                    <h2>化合物性质（Compound Properties）
                        <el-button type="primary" class="download-button" @click="getMol">下载<i
                                class="el-icon-download el-icon--right"></i></el-button>
                    </h2>
                    <p>暂无数据....</p>
                </div>
                <div class="bottom-section">
                    <span class="info-title">其他名称和标识符（Other Names and Identifiers）:</span>
                    <p class="info-title">名称同义词（Name Synonym）:</p>
                    <div v-if="compoundData && compoundData.nameSynonym">
                        <el-tag size="small" v-for="(synonym, index) in getNameSynonyms(compoundData.nameSynonym)"
                            :key="index" class="synonym-tag">
                            {{ synonym }}
                        </el-tag>
                    </div>
                    <div v-else>
                        暂无数据....
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
// import axios from 'axios';

export default {
    name: 'CompoundLibrary',
    data() {
        return {
            compoundData: null, // 用于存储从 Axios 请求获得的数据
            temp: null, // 用于存储从 Axios 请求获得的数据
            searchQuery: '', // 用户传递的搜索查询
            loading: true, // 用于显示加载状态
            imgPath: '',
            index: '',
            show: false,
            inputText: '请输入化合物的CAS号或名称或smiles',
            pageHeight: 0
        };
    },
    created() {
        this.pageHeight = this.getPageHeight();

        let localData = localStorage.getItem("CompoundData");
        // console.log(localData);

        // console.log(this.$route.query.keyword);
        if (this.$route.query.keyword == undefined ) {
            this.show = false;
            return;
        }



        // 如果没有数据，或者数据不是当前搜索的数据，就重新请求
        if (localData == null || JSON.parse(localData)['name'] != this.$route.query.keyword) {

            console.log('如果没有数据，或者数据不是当前搜索的数据，就重新请求');
            this.searchQuery = this.$route.query.keyword;
            this.$axios.post('/CompoundDB/CompoundSearch', { value: this.searchQuery }).then((res) => {
                if (res.data.status != '200') {
                    this.$message.error(res.data.msg);
                    return;
                } else if (res.data.data.length == 0) {
                    this.$message.error('没有数据');
                    return;
                }
                let data = res.data.data;
                this.temp = data[0];
                this.putdata();
                this.show = true;
                this.loading = false;
                localStorage.setItem("CompoundData", JSON.stringify(data));
            })
        }
        else {
            if (localData != null) {
                console.log('localData != null');

                this.show = true;
                this.compoundData = JSON.parse(localData);
                // console.log(this.compoundData);
                this.temp = this.compoundData['data'][0];
                this.putdata();
                this.loading = false;

            }
            else if (this.$route.params.keyword && this.$route.params.result) {
                console.log('this.$route.params.keyword && this.$route.params.result');
                this.show = true;
                this.compoundData = this.$route.params.result;
                this.temp = this.compoundData[0];
                this.putdata();
                this.loading = false;
            } else if (this.$route.query.keyword) {
                this.searchQuery = this.$route.query.keyword;

                this.$axios.post('/CompoundDB/CompoundSearch', { value: this.searchQuery }).then((res) => {
                    if (res.data.status != '200') {
                        this.$message.error(res.data.msg);
                        return;
                    } else if (res.data.data.length == 0) {
                        this.$message.error('没有数据');
                        return;
                    }
                    let data = res.data.data;
                    this.temp = data[0];
                    this.putdata();
                    this.show = true;
                    this.loading = false;

                })
            }
        }


    },
    methods: {
        getNameSynonyms(synonyms) {
            if (!synonyms) return undefined;
            return synonyms.split('|').filter(item => item && item !== " ");
        },
        formatMolecularFormula(formula) {
            // 使用正则表达式查找所有数字并替换为下标标签
            if (formula === undefined) {
                return '暂无数据....';

            }
            return formula.replace(/(\d+)/g, '<sub>$1</sub>');
        },
        getPageHeight() {
            return (window.innerHeight || document.documentElement.scrollHeight || document.body.scrollHeight) / 10 * 9.2 - 500;
        },
        putdata() {
            this.compoundData = this.temp;
            // console.log(this.compoundData);
            this.imgPath = this.compoundData.imgPath;
        },
        getMol() {
            // console.log("dianji");
            // 链接到 this.compoundData.imgPath
            window.open(this.compoundData.molPath);
        },
        performSearch() {
            // 当搜索按钮被点击时执行
            this.$axios.post('' + '/CompoundDB/CompoundSearch', { value: this.searchQuery })
                .then((response) => {
                    this.compoundData = response.data.data; // 将数据存储在 compoundData 中
                    // console.log(this.compoundData);
                    if (this.compoundData.length === 0) {
                        this.$message({
                            message: '没有数据',
                            type: 'error'
                        });
                        // console.log("没有数据");
                        this.$router.push({
                            name: 'NotFound',
                            params: { keyword: this.searchQuery, index: this.index }
                        });
                    }
                    else {
                        this.temp = this.compoundData[0];
                        this.putdata();
                        this.show = true;
                    }
                })
                .catch(() => {
                    // console.log("请求失败");
                    // console.log(error);
                    this.loading = false; // 请求失败，关闭加载动画
                });
        },
    }
}
</script>



<style scoped>
.content {
    max-width: 90vw;
    margin: 0 auto;
    padding: 20px;
}

.input-container {
    text-align: center;
    margin-bottom: 20px;
}

.input-with-select .el-input__inner {
    border-radius: 20px;
    border-color: #409EFF;
}

.input-with-select .el-button {
    border-radius: 0 20px 20px 0;
}

.container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    background-color: #FFF;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.left-panel,
.right-panel {
    flex: 1;
    padding: 20px;
    max-width: 100%
}
.info-item {
    margin-bottom: 15px;
}

.info-title {
    font-weight: bold;
    color: #333;
}

.info-value {
    margin-left: 5px;
    color: #666;
}

.compound-image {
    width: 100%;
    height: auto;
    max-width: 300px;
    margin-top: 20px;
    border-radius: 8px;
}

.top-section h2,
.bottom-section h3 {
    color: #409EFF;
    margin-bottom: 15px;
}

.download-button {
    float: right;
    margin-left: 20px;
}

@media (max-width: 80vw) {
    .container {
        flex-direction: column;
    }

    .left-panel,
    .right-panel {
        max-width: 100%;
    }
}
.info-item .info-value {
    display: inline-block; /* 或者可以设置为 block，取决于你的具体需求 */
    max-width: 100%; /* 限制最大宽度，避免超出父容器 */
    white-space: normal; /* 允许文本换行 */
    word-wrap: break-word; /* 在长单词或URL地址需要时进行断行 */
}

.synonym-tag {
    margin: 5px;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import i18n from '@/lang'
import Vuex from 'vuex'
import axios from 'axios'

import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css

const show = ''
axios.create({

  withCredentials: true
})
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.timeout = 500000;
axios.defaults.withCredentials = true;  // 允许携带cookie 
axios.defaults.baseURL = 'https://proteinuniverse.com.cn'
// axios.defaults.baseURL = 'https://proteinuniverse.com.cn:8080'
// axios.defaults.baseURL = 'https://192.168.30.99'
// axios.defaults.headers['token'] = localStorage.token;
axios.defaults.withCredentials = true
axios.interceptors.request.use(
  config => {
    
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['token'] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

Vue.prototype.$shouw = show

Vue.use(ElementUI) //使用elementUI
Vue.use(Vuex)
Vue.prototype.$axios = axios
// 关闭vue生产提示
Vue.config.productionTip = false
// 创建vue实例对象 -- vm
new Vue({
  el: "#app",
  // 完成了这个功能：将APP组件放入窗口中
  render: h => h(App),
  // i18n,
  router: router,
  store: store,

})





import Vue from 'vue'
import VueRouter from 'vue-router'
import DigitalEnzymeLibrary from '../views/Enzyme_library_and_enzymatic_reactions/DigitalEnzymeLibrary.vue'
import DigitalEnzymeLibraryBox from '../views/Enzyme_library_and_enzymatic_reactions/DigitalEnzymeLibraryBox.vue'
import CompoundLibrary from '../views/Enzyme_library_and_enzymatic_reactions/CompoundLibrary.vue'
import SolidEnzymeLibrary from '../views/Enzyme_library_and_enzymatic_reactions/SolidEnzymeLibrary.vue'
import EnzymeLibraryInformationVerification from '../views/Information_entry_and_verification/EnzymeLibraryInformationVerification.vue'
import InformationEntryVue from '@/views/Enzyme_library_and_enzymatic_reactions/InformationEntry.vue'
import SearchBox from '@/views/Search.vue'
import MutantInformationInput from '@/views/Information_entry_and_verification/MutantInformationInput.vue'
import NotFound from '@/views/NotFound.vue'
import SequenceBox from '@/views/Enzyme_library_and_enzymatic_reactions/SequenceBox.vue'
import CustomerListLeadsVue from '@/views/company/CustomerListLeads.vue'
import companyInformationVue from '@/views/company/companyInformation.vue'
import loginVue from '@/views/user/logIn.vue'
import RightsManagement from '@/views/user/RightsManagement.vue'
import PermissionAssignment from "@/views/user/PermissionAssignment.vue";

import FuzzySearchResult from "@/views/Enzyme_library_and_enzymatic_reactions/FuzzySearchResults.vue";
import DigitalEnzymeLibraryFuzzy from "@/views/Enzyme_library_and_enzymatic_reactions/DigitalEnzymeLibraryFuzzy.vue";
import ReaxysData from "@/views/Enzyme_library_and_enzymatic_reactions/ReaxysData.vue";
import FuzzySearchResultCompoundDB from "@/views/Enzyme_library_and_enzymatic_reactions/FuzzySearchResultCompoundDB.vue";
import MoreDetailedSearchResults from "@/views/Enzyme_library_and_enzymatic_reactions/MoreDetailedSearchResults.vue";
import ExperimentBox from "@/views/Information_entry_and_verification/mutant/ExperimentBox.vue";
import VirtualEvolution from "@/views/Enzyme_library_and_enzymatic_reactions/VirtualEvolution.vue";
import sequenceResult from "@/views/Enzyme_library_and_enzymatic_reactions/sequenceResult.vue";


import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path:'/sequenceResult',
    name:'sequenceResult',
    component:sequenceResult
  }
  ,
  {
    path: '/VirtualEvolution',
    name: 'VirtualEvolution',
    component: VirtualEvolution,
  },
  {
    path: '/Experiment',
    name: 'Experiment',
    component: ExperimentBox,
  },
  {
    path: '/reaxys',
    name: 'ReaxysData',
    component: ReaxysData,
  },
  {
    path: '/DigitalEnzymeLibraryFuzzy',
    name: "底物到三元关系",
    component: DigitalEnzymeLibraryFuzzy
  },

  {
    path: '/FuzzySearchResult',
    name: 'FuzzySearchResult',
    component: FuzzySearchResult,
  },
  {
    path: '/DigitalEnzymeLibrary',
    name: '数字化酶库',
    component: DigitalEnzymeLibrary, meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
  },
  {
    path: '/DigitalEnzymeLibraryBox',
    name: '数字化酶库表格页面',
    component: DigitalEnzymeLibraryBox, meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
  },
  {
    path: '/FuzzySearchResultCompoundDB',
    name: 'FuzzySearchResultCompoundDB',
    component: FuzzySearchResultCompoundDB,
    // meta: {
    //   requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    // },
  },
  {
    path: '/MoreDetailedSearchResults',
    name: '更加详细的搜索结果',
    component: MoreDetailedSearchResults,
  }
  ,
  {
    path: '/CompoundLibrary',
    name: '化合物库', meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
    component: CompoundLibrary
  },
  {
    path: '/SolidEnzymeLibrary',
    name: '实体酶库', meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
    component: SolidEnzymeLibrary
  },
  {
    path: '/EnzymeLibraryInformationVerification',
    name: '酶库等信息核对', meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
    component: EnzymeLibraryInformationVerification
  },
  {
    path: '/InformationEntryVue',
    name: '突变体及反应结构息录入', meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
    component: InformationEntryVue
  },
  {
    path: '/Search',
    name: "搜索", meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
    component: SearchBox
  },
  {
    path: '/MutantInformationInput',
    name: "突变体及反应结果信息录入", meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
    component: MutantInformationInput
  }, {
    path: '/NotFound',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/Sequence',
    name: '酶序列信息', meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
    component: SequenceBox
  }, {
    path: '/CustomerListLeads',
    name: '目标客户名单及线索', meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
    component: CustomerListLeadsVue
  }, {
    path: '/companyinformation',
    name: '公司信息', meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
    component: companyInformationVue
  },
  { path: '/login', name: 'loginVue', component: loginVue },
  {
    path: '/RightsManagement', // 权限管理
    name: '权限管理',
    component: RightsManagement,
    meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
  },
  {
    path: '/PermissionAssignment', // 权限分配
    name: '权限分配',
    component: PermissionAssignment,
    meta: {
      requireAuth: true // 配置此条，进入页面前判断是否需要登陆
    },
  }

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import { EventBus } from '@/eventBus'
// token 过期 返回 false  未过期返回 true
// const baseURL ='https://192.168.30.66:8080'
// router.beforeEach((to, from, next) => {
//   console.log("to", to);
//   if (to.path === '/login') {

//     next();
//   }
//   else if (to.meta.requireAuth === true) {  // 判断该路由是否需要登录权限
//     if (localStorage.token) {  // 通过vuex state获取当前的token是否存在
//       axios.defaults.headers['token'] = localStorage.token;
//       axios.get(baseURL+"/User/Token").then(res => {
//         if (res.data == false) {
//           localStorage.setItem('show', 'false')
//           EventBus.$emit('login-fail')
//           localStorage.removeItem('token')
//           localStorage.removeItem('login')
//           next({
//             path: '/login',
//           })
//         }
//         else if (to.path === '/login') {
//           EventBus.$emit('login-fail')
//           next();
//            // 如果已登录且目标路由是登录页，则重定向到首页
//         } 
//         else {
//           next();
//         }
//       })
//     }
//     else {
//       next({
//         path: '/login',
//         query: { redirect: to.fullPath }  // 将跳转的路由path作为参数，登录成功后跳转到该路由
//       })
//     }
//   }
//   else {
//     // 如果不需要登录权限，直接进入路由界面
//     if (to.path === '/login') {
//       // 先判断token是否过期，过期则跳转到登录界面，未过期则跳转到首页
//       axios.get(baseURL+"/User/Token").then(res => {
//         if (res.data == true) {
//           EventBus.$emit('login-success')
//           next('/');
//         }
//         else {
//           localStorage.removeItem('token')
//           localStorage.removeItem('login')
//           EventBus.$emit('login-fail')
//           console.log("token过期");
//           next();
//         }
//       })
//     }
//     else {
//       next();
//     }
//   }
// })
const baseURL = 'https://proteinuniverse.com.cn';

// 封装检查token有效性的函数
function checkTokenValidity() {
  return axios.get(baseURL + "/User/Token", {
    headers: { 'token': localStorage.getItem('token') }
  })
    .then(res => res.data)
    .catch(error => {
      console.error("检查token时出错:", error);
      return false;  // 在发生错误时默认为无效token
    });
}
function checkRouterTokenValidity(parmas) {
  return axios.get(baseURL + "/User/routeToken?moduleCodes=" + parmas, {
    headers: { 'token': localStorage.getItem('token') }
  },).then(res => res.data).catch(error => {
    console.error("检查路由权限token时出错:", error);
    return false;  // 在发生错误时默认为无效token
  });

}
function clearLocalStorage() {
  localStorage.removeItem('token');
  localStorage.removeItem('login');
  localStorage.setItem('show', 'false');
}
router.beforeEach(async (to, from, next) => {
  console.log("to", to);
  // 如果是登录页面且用户已经登录，则重定向到首页
  if (to.path === '/login' && localStorage.getItem('token')) {
    const isValidToken = await checkTokenValidity();
    if (isValidToken) {
      EventBus.$emit('login-success');
      next('/');
      return;
    } else {
      clearLocalStorage();
      EventBus.$emit('login-fail');
      next('/login');
      return;
    }
  }

  // 对于需要登录权限的路由
  if (to.meta.requireAuth) {
    if (localStorage.getItem('token')) {
      // 验证token是否有效
      const isValidToken = await checkTokenValidity();
      // 验证token是否有权限访问当前路由
      const isValidRouterToken = await checkRouterTokenValidity(to.name);
      console.log("isValidRouterToken", isValidRouterToken);
      if (!isValidToken) {
        clearLocalStorage();
        EventBus.$emit('login-fail');
        next('/login');
      } else {


        if (isValidToken && isValidRouterToken.status == '200') {
          next();

        } else {
          // 提示没有权限访问
          EventBus.$emit('Verify-permissions-fail')
          next("/");
        }
        return;
      }
    } else {
      clearLocalStorage();
      EventBus.$emit('login-fail');
      next('/login?redirect=' + to.fullPath);
    }
  } else {
    next();
  }
});





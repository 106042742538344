<template>
  <div v-show="showPage">
    <div class="adduser">
      <el-button type="primary" @click="adduser()">新增用户</el-button>
      <el-dialog title="新增用户" :visible.sync="dialogFormVisible">
        <el-form :model="form" :rules="rules">
          <el-form-item label="用户名" :label-width="formLabelWidth">
            <el-input v-model="form.username" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码" :label-width="formLabelWidth">
            <el-input v-model="form.password" autocomplete="off">
              <template>
                <el-button slot="append" type="primary" @click="randowPassword">
                  生成密码
                </el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="真实姓名" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号" :label-width="formLabelWidth">
            <el-input v-model="form.tel" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" :label-width="formLabelWidth">
            <el-input v-model="form.email" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </el-dialog>


    </div>
    <div class="container">
      <el-table :data="tableData" border style="border-radius: 10px;">
        <el-table-column label="用户名">
          <template slot-scope="scope">
            <i class="el-icon-user-solid"></i>
            <span style="margin-left: 10px">{{ scope.row.username }}</span>
          </template>
        </el-table-column>
        <el-table-column label="邮箱">
          <template slot-scope="scope">
            <span>
              {{ scope.row.email }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="密码">
          <template slot-scope="scope">
            <span>
              {{ scope.row.password }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="真实姓名">
          <template slot-scope="scope">
            <span>
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="权限">

          <template slot-scope="scope">
            <div v-if="scope.row.role != null">
              <el-tag v-for="(role, index) in scope.row.role.split('，')" :key="index" :type="department[role]"
                style="margin-right: 8px;" @click='tagClick(scope.row)'>
                {{ role }}
              </el-tag>
            </div>
            <div v-else>
              <el-tag type="info">无</el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="电话">
          <template slot-scope="scope">
            <span>
              {{ scope.row.phone }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button size="mini" @click="edit(scope.row)" style="margin-right: 8px;" type="primary"
              icon="el-icon-edit">编辑</el-button>
            <el-dialog title="编辑用户" :visible.sync="editDialogVisible">
              <el-form :model="form2">
                <el-form-item label="用户名" :label-width="formLabelWidth">
                  <el-input v-model="form2.username" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" :label-width="formLabelWidth">
                  <el-input v-model="form2.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" :label-width="formLabelWidth">
                  <el-input v-model="form2.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" :label-width="formLabelWidth">
                  <el-input v-model="form2.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="真实姓名" :label-width="formLabelWidth">
                  <el-input v-model="form2.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户身份" :label-width="formLabelWidth">
                  <el-select v-model="form2.role" filterable allow-create default-first-option
                    placeholder="请选择用户的身份，或手动输入">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>

              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <template>
                  <el-button @click="enter()">确定</el-button>
                </template>
              </div>
            </el-dialog>

            <el-button size="mini" type="danger" @click="deleteUser(scope.row)" icon="el-icon-delete">删除</el-button>
            <el-button size="mini" type="warning" @click="assignPermissions(scope.row)" style="margin-left: 8px;"
              icon='el-icon-s-operation'>分配权限</el-button>
          </template>
        </el-table-column>


      </el-table>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-tree :data="treeData" show-checkbox ref="tree" node-key="id"
      :default-expand-all=true
         :props="defaultProps"
        @check-change="checkChange"
       >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePermissions">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>



// import routes from "@/router/index.js";
export default {
  data() {
    return {
      dialogVisible: false,
      selectvalue: '',
      showPage: false,
      tableData: [],
      shouwAddUser: false,
      dialogFormVisible: false,
      department: {
        "修改": 'success',
        "删除": 'danger',
        "查看": '',
      },
      editDialogVisible: false,
      form: {
        username: '',
        name: '',
        email: '',
        password: '',
        department: '',
        tel: '',
        user: '',
        role: ''
      },
      form1: {   // 字段映射
        '用户名': 'name',
        '邮箱': 'email',
        '密码': 'password',
        '权限': 'department',
        '电话': 'tel'
      },
      form2: {
        name: '',
        email: '',
        password: '',
        tel: '',
        role: '',
        oldtel: '',
        username: ''
      },
      formLabelWidth: '120px',
      rules: {
      },
      options: [
        { label: '研发人员', value: '研发人员' },
        { label: '销售人员', value: '销售人员' },
        { label: '新人', value: '新人' },
      ],

      treeData: [
        {
          id: 1001,
          label: '酶库及酶促反应',
          children: [
            { label: '数字化酶库', value: 'value1', id: 1 },
            { label: '实体酶库', value: 'value2', id: 2 },
            { label: '化合物库', value: 'value3', id: 3 },
            { label: '微生物库', value: 'value4', id: 4 },
            { label: '酶促反应概率及查询', value: 'value5', id: 5 },
            { label: '底物-酶匹配查询', value: 'value6', id: 6 },
            { label: '多环境变量酶查询', value: 'value7', id: 7 },
            { label: '外部数据库查询入口', value: 'value8', id: 8 },
            { label: '酶序列信息', value: 'value39', id: 39 }
          ]
        },
        {
          id: 1002,
          label: '蛋白及细胞因子',
          children: [
            { label: '细胞因子及结构', value: 'value9', id: 9 },
            { label: '主要蛋白及结构', value: 'value10', id: 10 },
            { label: '人体主要通路', value: 'value11', id: 11 }
          ]
        },
        {
          id: 1003,
          label: '产业到达及客户维护',
          children: [
            { label: '目标客户名单及线索', value: 'value12', id: 12 },
            { label: '存量客户维护及合作', value: 'value13', id: 13 },
            { label: '营销计划及实施', value: 'value14', id: 14 }
          ]
        },
        {
          id: 1004,
          label: '订单信息及推进',
          children: [
            { label: '订单概览', value: 'value15', id: 15 },
            { label: '筛选优化', value: 'value16', id: 16 },
            { label: '拓扑设计及验证', value: 'value20', id: 20 },
            { label: '定向进化', value: 'value24', id: 24 },
            { label: '分析方法', value: 'value25', id: 25 },
            { label: '发酵生产', value: 'value26', id: 26 },
            { label: '质量控制', value: 'value27', id: 27 },
            { label: '进销存管理', value: 'value28', id: 28 }
          ]
        },
        {
          id: 1005,
          label: '自主研发项目管理',
          children: [
            { label: '预先研究', value: 'value29', id: 29 },
            { label: '立项审核', value: 'value30', id: 30 },
            { label: '研发进度管理', value: 'value31', id: 31 }
          ]
        },
        {
          id: 1006,
          label: '信息核对及录入',
          children: [
            { label: '突变体及反应结果信息录入', value: 'value32', id: 32 },
            { label: '酶库等信息核对', value: 'value33', id: 33 }
          ]
        },
        {
          id: 1007,
          label: '更多信息',
          children: [
            { label: '突变体及专利查重', value: 'value34', id: 34 },
            { label: '下游应用查询', value: 'value35', id: 35 },
            { label: '文献及专利信息查询', value: 'value36', id: 36 },
            { label: '用户权限管理', value: 'value37', id: 37 },
            { label: '其他', value: 'value38', id: 38 }
          ]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      defaultExpand: [],
      defaultChecked: [],
      allKeys :[1001,1002,1003,1004,1005,1006,1007],
      dataRow: [], // 存放要修改的用户的数据,
      permissionsData: {}, // 最后提交的数据
    }
  },
  created() {
    this.getUser();
  },
  methods: {
    /* eslint-disable */
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.dialogVisible = false;

          done();
        })
        .catch(_ => { console.log(_); });
    },
    /* eslint-enable */
    adduser() {
      this.$axios.get('/User/addUser').then(res => {
        if (res.data.status == '200') {
          this.dialogFormVisible = true;
        }
      }).catch(err => {
        console.log(err);
      })

    },
    submit() {
      this.dialogFormVisible = false;
      const userInfo = `用户名：${this.form.username}\n密码：${this.form.password}\n手机号：${this.form.tel}\n邮箱：${this.form.email}`;
      // this.$axios.post('/User/addUser',{
      this.$axios.post('/User/addUser/forms', {
        username: this.form.username,
        name: this.form.name,
        password: this.form.password,
        email: this.form.email,
        phone: this.form.tel,
        role: ""
      }).then(res => {

        if (res.data.status == '200') {
          this.$message({
            message: '新增用户成功',
            type: 'success'
          });
          // 展示新增用户信息
          const h = this.$createElement;
          this.$alert(h('p', null, [
            h('span', null, `用户名：${this.form.username}`),
            h('br', null, ''),
            h('span', null, `密码：${this.form.password}`),
            h('br', null, ''),
            h('span', null, `手机号：${this.form.tel}`),
            h('br', null, ''),
            h('span', null, `邮箱：${this.form.email}`),
          ]), '新用户信息', {
            confirmButtonText: '复制到剪贴板',
            callback: action => {

              if (action === 'confirm') {
                this.copyToClipboard(userInfo);
                this.$message({
                  type: 'success',
                  message: '信息已复制到剪贴板'
                });
              }

            }
          });

          this.getUser();
        } else {
          this.$message({
            message: '新增用户失败',
            type: 'error'
          });
        }
      }).catch(err => {
        console.log(err);
        this.$message({
          message: '新增用户失败',
          type: 'error'
        });
      })


    },
    copyToClipboard(text) {
      const el = document.createElement('textarea');  // 创建一个textarea元素
      el.value = text;  // 设置其值为需要复制的文本
      document.body.appendChild(el);  // 将元素添加到DOM中
      el.select();  // 选择元素中的文本
      document.execCommand('copy');  // 执行复制命令
      document.body.removeChild(el);  // 移除元素
    },
    edit(row) {
      if (row.name == 'root') {
        this.$message({
          message: 'root用户不允许修改',
          type: 'error'
        });
        return;
      }
      this.form2 = row;
      this.form2.oldtel = row.phone;
      console.log(this.form2);
      this.$nextTick(() => {
        this.editDialogVisible = true;
      });
    },
    enter() {

      this.$confirm('是否提交修改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post('/User/updateUser', {
          username: this.form2.name,
          password: this.form2.password,
          newPhone: this.form2.tel,
          email: this.form2.email,
          oldPhone: this.form2.oldtel,
          role: this.form2.role,
          name: this.form2.name
        }).then(() => {
          console.log(this.selectvalue);
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
          this.editDialogVisible = false;
          this.getUser();

        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

    },
    getUser() {
      this.$axios.get('/User/UserManagement', {
        headers: {
          'token': localStorage.token
        }
      }
      ).then(res => {

        if (res.data.status == '200') {
          this.tableData = res.data.data;

          this.$message({
            message: '获取用户信息成功',
            type: 'success'
          });
          this.showPage = true;
        } else {
          this.$message({
            message: '获取用户信息失败,检测是否是管理员权限，或者重新登录',
            type: 'error'
          });
          this.$router.push('/');
        }
        console.log(res.data);
      }).catch(err => {
        console.log(err);
        this.$message({
          message: '获取用户信息失败,检测是否是管理员权限，或者重新登录',
          type: 'error'
        });
        this.$router.push('/');
      })
    },
    deleteUser(row) {
      if (row.name == 'root') {
        this.$message({
          message: 'root用户不允许删除',
          type: 'error'
        });
        return;
      }

      this.$axios.post('/User/deleteUser', {
        username: row.username,
        role: row.role
      }).then(res => {
        if (res.data.status == '200') {
          this.$message({
            message: '删除用户成功',
            type: 'success'
          });
          this.getUser();
        } else {
          this.$message({
            message: '删除用户失败',
            type: 'error'
          });
        }
      }).catch(err => {
        console.log(err);
        this.$message({
          message: '删除用户失败',
          type: 'error'
        });
      })

    },
    assignPermissions(row) {

      // 查看用户所有权限
      this.$axios.get('/User/UserAllPermissions?username=' + row.username + '&role=' + row.role).then((res) => {

        let defaultExpand = [];
        let defaultChecked = [];

        this.dialogVisible = true;
        let data = res.data.data;
        // 找到data中的所有的键
        let keys = Object.keys(data);
        // 将键和  treeData 对比，如果treeData中某一个id的键全部在keys中，则将这个id添加到defaultExpand中
        for (let i = 0; i < this.treeData.length; i++) {
          // 获得当前id的所有子节点的label
          let children = this.treeData[i].children.map((item) => {
            return item;
          });
          // 循环所有的键，如果有一个键在children中，则将这个id添加到defaultExpand中
          for (let index = 0; index < children.length; index++) {
            const element = children[index];
            if (keys.includes(String(element.label))) {
              defaultChecked.push(element.id);
              defaultExpand.push(this.treeData[i].id);
            }
          }
        }
        // 对 defaultExpand 进行去重
        defaultExpand = Array.from(new Set(defaultExpand));
        this.$nextTick(() => {
          // this.$refs.tree.setCheckedNodes(defaultExpand);
          this.$refs.tree.setCheckedKeys(defaultChecked);
        }
        )
        this.defaultChecked = defaultChecked;
        this.defaultExpand = defaultExpand;
        this.dataRow = row;
      }
      )
    },
    tagClick(row) {
      // 跳转到权限分配页面
      console.log(row.role);
      // if (row.role == "超级管理员"){
      //   this.$message({
      //     message: '超级管理员不允许修改权限',
      //     type: 'error'
      //   });
      //   return;
      // }

      this.$router.push({
        name: '权限分配',
        params: { role: row.role, username: row.username }
      });
    },
    addedUser() {
      console.log(111);
    },
    randowPassword() {
      var length = 11;
      var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
      var retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.form.password = retVal;
    },
    /* eslint-disable */
    checkChange(node,status,childNodeStates){
      if (!this.defaultChecked.includes(node.id) && !this.allKeys.includes(node.id)  ){
        // 根据status的值，判断是选中还是取消选中
        if (status){
         this.permissionsData[node.label] = true;
        }else{
          this.permissionsData[node.label] = false;
        }
      }
    },
    /* eslint-enable */
    updatePermissions(){
      for (let key in this.permissionsData) {
        this.$axios.post('/User/UpdatePermissions', {
          username: this.dataRow.username,
          moduleCode: key,
          found: this.permissionsData[key],
        }).then((res) => {
          console.log(res);
        });
      }
      this.dialogVisible = false
      this.dataRow  = [];
    }
  }
}
</script>
scoped

<style scoped>

.el-table {
  border-radius: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
}

.adduser {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 数字化酶库的搜索条件
    digitalEnzymeLibraryQuery: '',
  },
  getters: {
  },
  mutations: {
    setDigitalEnzymeLibraryQuery(state, query) {
      state.digitalEnzymeLibraryQuery = query
    },
  },
  actions: {



  },
  modules: {
  }
})

<template>

    <div class="card-container">
        <el-table class="ecinfo" :data="tableData">
            <el-table-column prop="name" label="酶名称"></el-table-column>
            <el-table-column prop="ec" label="ec" width="100px"></el-table-column>
            <el-table-column prop="accessionNumber" label="uniportID"></el-table-column>
            <el-table-column prop="alternativeNamingDifferences" label="别名" width="600px">

                <template>
                    <el-tag size="small" v-for="(synonym, index) in nameSynonyms" :key="index" class="synonym-tag">
                        {{ synonym }}
                    </el-tag>
                </template>

            </el-table-column>

        </el-table>
        <h2>序列</h2>
        <div class="sequence-container" v-if="formattedSequence !== ''">

            <pre class="sequence"><el-tooltip class="item" effect="dark" content="复制" placement="top">
            <i class="el-icon-document" @click="copySequence"></i>
        </el-tooltip><br>{{ formattedSequence }}</pre>
        </div>
        <div v-else>
            暂无数据
        </div>
    </div>


</template>






<script>
export default {
    data() {
        return {
            rawSequence: '',
            formattedSequence: '',
            tableData: [],
            nameSynonyms: [],
        };
    },
    methods: {
        formatSequence(sequence) {
            const chunkSize = 80;
            let formatted = '';
            let maxWidth = 0; // 用于存储最长行的长度
            for (let i = 0; i < sequence.length; i += chunkSize) {
                const start = i;
                const end = Math.min(i + chunkSize, sequence.length);
                let chunk = sequence.substring(start, end);
                chunk = this.addSpacesEveryTenChars(chunk); // 在每10个字符后添加空格
                formatted += `${start} ${chunk}\n`; // 在序列前添加位置索引
                let lineWidth = this.measureTextWidth(`${start} ${chunk}`); // 计算这一行的像素宽度
                if (lineWidth > maxWidth) {
                    maxWidth = lineWidth; // 更新最大宽度
                }
            }
            this.formattedSequence = formatted;
            this.updateContainerWidth(maxWidth); // 更新容器宽度
        },

        addSpacesEveryTenChars(chunk) {
            return chunk.replace(/(.{10})/g, "$1 "); // 使用正则表达式每10个字符后添加空格
        },
        measureTextWidth(text) {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            // 模拟 <pre> 标签中的样式设置
            context.font = "16px 'Courier New', monospace"; // 根据CSS中的定义来设置字体和字号
            const textWidth = context.measureText(text).width;

            // 假设 <pre> 标签的样式如下，你需要调整这些值以匹配实际的样式
            const padding = 20; // <pre> 标签两边总共的内边距
            const border = 2; // <pre> 标签两边边框的宽度，如果有的话

            return textWidth + padding + border; // 返回计算后包含内边距和边框的宽度
        },

        updateContainerWidth(width) {
            this.$nextTick(() => {
                this.$el.querySelector('.sequence-container').style.width = `${width}px`;
                this.$el.querySelector('.ecinfo').style.width = `${width}px`;
            });
        },
        copySequence() {
            const el = document.createElement('textarea');
            el.value = this.rawSequence;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.$message({
                message: '已复制序列',
                type: 'success'
            });
        },
        getNameSynonyms(synonyms) {
            if (!synonyms) return undefined;
            this.nameSynonyms = synonyms.split('|').filter(item => item && item !== " ");
            return;

        },
    },


    created() {

        this.$axios.get("/EnzymeDB/EnzymeSearch?value=" + this.$route.query.value).then(response => {



            
            if (response.data.data == null) {
                this.$message({
                    message: '未找到相关数据',
                    type: 'warning'
                });
                this.$router.push({ name: "数字化酶库" });

                return;
            }

            try {
                if (response.data.data.length == 0) {
                    this.$message({
                        message: '未找到相关数据',
                        type: 'warning'
                    });
                    this.$router.push({ name: "数字化酶库" });

                    return;
                }
            } catch (error) {
                console.log(error);
            }



            this.rawSequence = response.data.data[0].sequence;
            this.tableData = response.data.data;

            this.getNameSynonyms(this.tableData[0].alternativeNamingDifferences);

            if (this.rawSequence == undefined) {
                return;
            }
            this.formatSequence(this.rawSequence);
            
        });

    }
};
</script>

<style scoped>

.synonym-tag {
    margin: 5px;
}

.el-table {
    border: 1px solid #ebeef5;
    /* 表格外边框颜色 */
    border-radius: 8px;
    /* 表格圆角 */
}

.el-table th {
    background-color: #f9fafc;
    /* 标题背景色 */
    color: #606266;
    /* 标题文字颜色 */
    font-weight: bold;
    /* 字体加粗 */
    padding: 10px 20px;
    /* 内边距 */
    text-align: left;
    /* 文本左对齐 */
}

.el-table td {
    padding: 10px 20px;
    /* 单元格内边距 */
    text-align: left;
    /* 文本左对齐 */
    border-bottom: 1px solid #ebeef5;
    /* 单元格底部边框颜色 */
}

.el-table .el-table__row:hover {
    background-color: #f5f7fa;
    /* 悬停效果的背景色 */
}


.card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* 垂直居中 */
    align-items: center;
    /* 水平居中 */
    margin: 0 auto;
    /* 自动外边距实现水平居中 */
    padding: 20px;
    /* 内边距 */
    border-radius: 8px;
    /* 圆角效果 */
    width: 80%;
    /* 可根据需要调整宽度 */
    max-width: 960px;
    /* 最大宽度限制 */
}

.sequence-container {
    width: 100%;
    /* 确保容器宽度与卡片容器相匹配 */
    display: flex;
    justify-content: center;
    /* 确保内容水平居中 */
}

.sequence {
    font-family: 'Courier New', monospace;
    white-space: pre;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    line-height: 1.6;
    margin-bottom: 5px;
}


h2 {
    text-align: center;
    color: #333;
    margin: 10px 0;
    /* 调整标题的上下间距 */
}
</style>

<template>
  <div>
    <!-- 反应式 -->
    <div>
      <div class="reaction-format">
        <div class="item" v-if="sub1Img || sub1name">
          <img v-if="sub1Img" :src="sub1Img" alt="Sub1 Image" class="reaction-img" @click="openImg(sub1Img)"
            style="cursor: pointer;" />
          <span v-if="sub1name" @click="jumpToCompound(sub1name)" class="hover-pointer">{{ sub1name }}</span>
        </div>
        <span v-if="(sub1Img && sub2Img) || (sub1name && sub2name)"> + </span>
        <div class="item" v-if="sub2Img || sub2name">
          <img v-if="sub2Img" :src="sub2Img" alt="Sub2 Image" class="reaction-img" @click="openImg(sub2Img)"
            style="cursor: pointer;" />
          <span v-if="sub2name" @click="jumpToCompound(sub2name)" class="hover-pointer">{{ sub2name }}</span>
        </div>
        <span class="arrow"> => </span>
        <div class="item" v-if="pro1Img || pro1name" style="cursor: pointer;">
          <img v-if="pro1Img" :src="pro1Img" alt="Pro1 Image" class="reaction-img" @click="openImg(pro1Img)" />
          <span v-if="pro1name" @click="jumpToCompound(pro1name)" class="hover-pointer">{{ pro1name }}</span>
        </div>
        <span v-if="(pro1Img && pro2Img) || (pro1name && pro2name)"> + </span>
        <div class="item" v-if="pro2Img || pro2name">
          <img v-if="pro2Img" :src="pro2Img" alt="Pro2 Image" class="reaction-img" style="cursor: pointer;"
            @click="openImg(pro2Img)" />
        </div>
        <span v-if="pro2name" @click="jumpToCompound(pro2name)" class="hover-pointer">{{ pro2name }}</span>
      </div>
    </div>
    <!-- 表格 -->
    <div class="grid-container">
      <template>
        <el-table :data="tableData"
        style="width: 100%"
        border
        stripe>
          <el-table-column prop="ec" label="EC Number"></el-table-column>
          <el-table-column prop="enzyme" label="Enzyme"></el-table-column>
          <el-table-column prop="organism" label="Organism"></el-table-column>
          <el-table-column prop="reversibility" label="Reversibility"></el-table-column>
          <el-table-column prop="source" label="Source"></el-table-column>
          <el-table-column prop="reviewd" label="Reviewed"></el-table-column>
          <el-table-column prop="pathway" label="Pathway"></el-table-column>
          <el-table-column prop="function" label="Function"></el-table-column>
          <el-table-column prop="reaction" label="Reaction"></el-table-column>
          <!-- <el-table-column prop="lv" label="Level"></el-table-column> -->
          <el-table-column prop="km" label="KM"></el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>
<script>
import { Loading } from 'element-ui';


export default {
  data() {
    return {
      tableData: [],
      tempTableData: [],
      sub1name: "",
      sub1Img: "",
      sub2name: "",
      sub2Img: "",
      pro1name: "",
      pro1Img: "",
      pro2name: "",
      pro2Img: "",


    }
  },
  created() {
    // 开始等待
    
    
/* eslint-disable */

    
    if (this.$route.query.href != undefined && this.$route.query.ec != undefined) {
     const loading =  Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      let reaction = atob(this.$route.query.href);
      let ec = this.$route.query.ec;
      console.log("z准备请求");
      this.$axios.post('/EnzymeDB/TriadicRelationshipByECReaction', {

        reaction: reaction,
        EC: ec

      }).then(response => {
        console.log(response.data.data);
        // 抽取 反应式和反应图片
        let res = response.data.data;
        // 处理回退数据
        for (let index = 0; index < res.length; index++) {
          let data = {
            ec: res[index].ec ? res[index].ec : res[index].EC,
            enzyme: res[index].enzyme,
            organism: res[index].organism,
            reversibility: res[index].reversibility,
            source: res[index].source,
            km: res[index].km,
            uniprot: res[index].uniprot,
            reaction: res[index].reaction,
            total: res[index].total,
            lv: res[index].lv,
            pathway: res[index].pathway,
            reviewd: res[index].reviewd,
          };
          let shub = res[index].substrateJS;
          let regex = /(?<!(\s*[{:,]\s*))(\")(?!(\s*[}:,]\s*))/g;
          shub = shub.replace(regex, "'");
          let da = JSON.parse(shub);
          for (let i = 0; i < da.length; i++) {
            if (i == 0) {
              data.sub1Img = da[i].imgPath;
              data.sub1name = da[i].name;
              data.subsource = da[i].subsource;
              data.subComment = da[i].subComment;
            }
            if (i == 1) {
              data.sub2Img = da[i].imgPath;
              data.sub2name = da[i].name;
            }
          }
          let pro = res[index].productJS;
          pro = pro.replace(regex, "'");
          let da1 = JSON.parse(pro);
          for (let i = 0; i < da1.length; i++) {
            if (i == 0) {
              data.pro1Img = da1[i].imgPath;
              data.pro1name = da1[i].name;
              data.prosource = da1[i].prosource;
              data.proComment = da1[i].proComment;
            }
            if (i == 1) {
              data.pro2Img = da1[i].imgPath;
              data.pro2name = da1[i].name;
            }

          }
          this.tempTableData.push(data);
          console.log(data);

        }
        this.tableData = this.tempTableData;
        this.sub1name = this.tableData[0].sub1name;
        this.sub1Img = this.tableData[0].sub1Img;
        this.sub2name = this.tableData[0].sub2name;
        this.sub2Img = this.tableData[0].sub2Img;
        this.pro1name = this.tableData[0].pro1name;
        this.pro1Img = this.tableData[0].pro1Img;
        this.pro2name = this.tableData[0].pro2name;
        this.pro2Img = this.tableData[0].pro2Img;

        loading.close();
      })


    }
  },
  methods: {
    jumpToCompound(name) {
      // 跳转到化合物库

      this.$axios.post('/CompoundDB/CompoundSearch', { value: name }).then((res) => {
        if (res.data.status != '200') {
          this.$message.error(res.data.msg);
          return;
        } else if (res.data.data.length == 0) {
          this.$message.error('没有数据');
          return;
        }
        let data = res.data.data;

        localStorage.setItem("CompoundData", JSON.stringify(data));
        let routeURL = this.$router.resolve({ name: '化合物库', query: { keyword: name } }).href;
        window.open(routeURL, '_blank');
      })

    },
    openImg(imgPath) {
      console.log(imgPath);

      // 新窗口打开图片
      window.open(imgPath, '_blank');

    },
  }
}
</script>

<style scoped>
.reaction-format {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 2%;
}

.reaction-img {
  width: 120px;
  /* 或者任何适合的尺寸 */
  height: 120px;
  /* 保持图片的宽高比例 */
  margin-bottom: 5px;
  /* 在图片和文字之间添加一些间隔 */
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  /* 确保项与项之间有间隔 */
}

.arrow {
  margin: 0 5px;
}

.hover-pointer {
  cursor: pointer;
  color: inherit;
  /* 默认颜色 */
}

.hover-pointer:hover {
  color: blue;
  /* 鼠标悬停时变为蓝色 */
}

/* 全局样式设置 */
.el-table {
  --el-border-color: #e4e7ed; /* 修改边框颜色 */
  --el-text-color: #606266; /* 修改文本颜色 */
  --el-background-base: #ffffff; /* 设置表格的背景颜色 */
  margin-top: 20px; /* 替换原来的负边距 */
}

/* 表头样式 */
.el-table th {
  background-color: #f2f6fc; /* 设置表头背景颜色 */
  color: #333; /* 设置表头文字颜色 */
  font-weight: bold; /* 字体加粗 */
}

/* 斑马纹样式 */
.el-table .el-table__row--striped:nth-child(odd) {
  background: #f9fafc; /* 设置斑马纹的颜色 */
}

.grid-container {
  display: grid;
  place-items: center; /* 水平和垂直居中 */
  height: auto; /* 调整高度为自动 */
  padding: 20px 0; /* 添加一些垂直填充，视需求而定 */
}


 
.el-table {
  width: 80%!important; /* 表格宽度为80% */
}

</style>
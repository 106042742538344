<!-- 权限分配页面 -->

<template>
	<div class="container">


		<div class="current-user">
			当前用户：{{ username }}
		</div>
		<el-card class="box-card">
			<el-collapse v-model="activeNames">
				<!-- 酶库及酶促反应 -->
				<el-collapse-item title="酶库及酶促反应" name="1">
					<div class="item">
						-数字化酶库
						<el-switch v-model="value1" @change="handleSwitchChange($event, '数字化酶库')"></el-switch>
					</div>
					<div class="item">
						-实体酶库
						<el-switch v-model="value2" @change="handleSwitchChange($event, '实体酶库')"></el-switch>
					</div>
					<div class="item">
						-化合物库
						<el-switch v-model="value3" @change="handleSwitchChange($event, '化合物库')"></el-switch>
					</div>
					<div class="item">
						-微生物库
						<el-switch v-model="value4" @change="handleSwitchChange($event, '微生物库')"></el-switch>
					</div>
					<div class="item">
						-酶促反应概率及查询
						<el-switch v-model="value5" @change="handleSwitchChange($event, '酶促反应概率及查询')"></el-switch>
					</div>
					<div class="item">
						-底物-酶匹配查询
						<el-switch v-model="value6" @change="handleSwitchChange($event, '底物-酶匹配查询')"></el-switch>
					</div>
					<div class="item">
						-多环境变量酶查询
						<el-switch v-model="value7" @change="handleSwitchChange($event, '多环境变量酶查询')"></el-switch>
					</div>
					<div class="item">
						-外部数据库查询入口
						<el-switch v-model="value8" @change="handleSwitchChange($event, '外部数据库查询入口')"></el-switch>
					</div>
					<div class="item">
						-酶序列信息
						<el-switch v-model="value39" @change="handleSwitchChange($event, '酶序列信息')"></el-switch>
					</div>
				</el-collapse-item>

				<!-- 蛋白及细胞因子 -->
				<el-collapse-item title="蛋白及细胞因子" name="2">
					<div class="item">
						-细胞因子及结构
						<el-switch v-model="value9" @change="handleSwitchChange($event, '细胞因子及结构')"></el-switch>
					</div>
					<div class="item">
						-主要蛋白及结构
						<el-switch v-model="value10" @change="handleSwitchChange($event, '主要蛋白及结构')"></el-switch>
					</div>
					<div class="item">
						-人体主要通路
						<el-switch v-model="value11" @change="handleSwitchChange($event, '人体主要通路')"></el-switch>
					</div>
				</el-collapse-item>

				<!-- 产业到达及客户维护 -->
				<el-collapse-item title="产业到达及客户维护" name="3">
					<div class="item">
						-目标客户名单及线索
						<el-switch v-model="value12" @change="handleSwitchChange($event, '目标客户名单及线索')"></el-switch>
					</div>
					<div class="item">
						-存量客户维护及合作
						<el-switch v-model="value13" @change="handleSwitchChange($event, '存量客户维护及合作')"></el-switch>
					</div>
					<div class="item">
						-营销计划及实施
						<el-switch v-model="value14" @change="handleSwitchChange($event, '营销计划及实施')"></el-switch>
					</div>
				</el-collapse-item>

				<!-- 订单及推进信息 -->
				<el-collapse-item title="订单及推进信息" name="4">
					<div class="item">
						-订单概览
						<el-switch v-model="value15" @change="handleSwitchChange($event, '订单概览')"></el-switch>
					</div>
					<div class="item">
						-筛选优化
						<el-switch v-model="value16" @change="handleSwitchChange($event, '筛选优化')"></el-switch>
					</div>
					<div class="item">
						-拓扑设计及验证
						<el-switch v-model="value20" @change="handleSwitchChange($event, '拓扑设计及验证')"></el-switch>
					</div>
					<div class="item">
						-定向进化
						<el-switch v-model="value24" @change="handleSwitchChange($event, '定向进化')"></el-switch>
					</div>
					<div class="item">
						-分析方法
						<el-switch v-model="value25" @change="handleSwitchChange($event, '分析方法')"></el-switch>
					</div>
					<div class="item">
						-发酵生产
						<el-switch v-model="value26" @change="handleSwitchChange($event, '发酵生产')"></el-switch>
					</div>
					<div class="item">
						-质量控制
						<el-switch v-model="value27" @change="handleSwitchChange($event, '质量控制')"></el-switch>
					</div>
					<div class="item">
						-进销存管理
						<el-switch v-model="value28" @change="handleSwitchChange($event, '进销存管理')"></el-switch>
					</div>
				</el-collapse-item>

				<!-- 自主研发项目管理 -->
				<el-collapse-item title="自主研发项目管理" name="5">
					<div class="item">
						-预先研究
						<el-switch v-model="value29" @change="handleSwitchChange($event, '预先研究')"></el-switch>
					</div>
					<div class="item">
						-立项审核
						<el-switch v-model="value30" @change="handleSwitchChange($event, '立项审核')"></el-switch>
					</div>
					<div class="item">
						-研发进度管理
						<el-switch v-model="value31" @change="handleSwitchChange($event, '研发进度管理')"></el-switch>
					</div>
				</el-collapse-item>

				<!-- 信息录入及核对 -->
				<el-collapse-item title="信息录入及核对" name="6">
					<div class="item">
						-突变体及反应结果信息录入
						<el-switch v-model="value32" @change="handleSwitchChange($event, '突变体及反应结果信息录入')"></el-switch>
					</div>
					<div class="item">
						-酶库等信息核对
						<el-switch v-model="value33" @change="handleSwitchChange($event, '酶库等信息核对')"></el-switch>
					</div>
				</el-collapse-item>

				<!-- 更多信息 -->
				<el-collapse-item title="更多信息" name="7">
					<div class="item">
						-突变体及专利查重
						<el-switch v-model="value34" @change="handleSwitchChange($event, '突变体及专利查重')"></el-switch>
					</div>
					<div class="item">
						-下游应用查询
						<el-switch v-model="value35" @change="handleSwitchChange($event, '下游应用查询')"></el-switch>
					</div>
					<div class="item">
						-文献及专利信息查询
						<el-switch v-model="value36" @change="handleSwitchChange($event, '文献及专利信息查询')"></el-switch>
					</div>
					<div class="item">
						-权限管理
						<el-switch v-model="value37" @change="handleSwitchChange($event, '权限管理')"></el-switch>
					</div>
					<div class="item">
						-权限分配
						<el-switch v-model="value38" @change="handleSwitchChange($event, '权限分配')"></el-switch>
					</div>
				</el-collapse-item>
			</el-collapse>
		</el-card>
	</div>
</template>
  
  

<script  >

export default {
	data() {
		return {
			activeNames: [],
			value1: false,
			value2: false,
			value3: false,
			value4: false,
			value5: false,
			value6: false,
			value7: false,
			value8: false,
			value9: false,
			value10: false,
			value11: false,
			value12: false,
			value13: false,
			value14: false,
			value15: false,
			value16: false,
			value17: false,
			value18: false,
			value19: false,
			value20: false,
			value21: false,
			value22: false,
			value23: false,
			value24: false,
			value25: false,
			value26: false,
			value27: false,
			value28: false,
			value29: false,
			value30: false,
			value31: false,
			value32: false,
			value33: false,
			value34: false,
			value35: false,
			value36: false,
			value37: false,
			value38: false,
			value39: false,
			switchesMapping: {
				'1': {
					'数字化酶库': 'value1',
					'实体酶库': 'value2',
					'化合物库': 'value3',
					'微生物库': 'value4',
					'酶促反应概率及查询': 'value5',
					'底物-酶匹配查询': 'value6',
					'多环境变量酶查询': 'value7',
					'外部数据库查询入口': 'value8',
					'酶序列信息':"value39",
				},
				'2': {
					'细胞因子及结构': 'value9',
					'主要蛋白及结构': 'value10',
					'人体主要通路': 'value11',
				},
				'3': {
					'目标客户名单及线索': 'value12',
					'存量客户维护及合作': 'value13',
					'营销计划及实施': 'value14',
				},
				'4': {
					'订单概览': 'value15',
					'筛选优化': 'value16',
					'拓扑设计及验证': 'value20',
					'定向进化': 'value24',
					'分析方法': 'value25',
					'发酵生产': 'value26',
					'质量控制': 'value27',
					'进销存管理': 'value28',
				},
				'5': {
					'预先研究': 'value29',
					'立项审核': 'value30',
					'研发进度管理': 'value31',
				},
				'6': {
					'突变体及反应结果信息录入': 'value32',
					'酶库等信息核对': 'value33',
				},
				'7': {
					'突变体及专利查重': 'value34',
					'下游应用查询': 'value35',
					'文献及专利信息查询': 'value36',
					'用户权限管理': 'value37',
					'用户权限分配': 'value38',
				},
			},
			username: '',

		};
	},
	created() {
		// this.getPermission();
		// 获取路由中的参数
		let role = this.$route.params.role;
		let username = this.$route.params.username;
		this.username = username;

		if (role === undefined) {
			this.$router.push('/');
		}
		else {
			this.$axios.get('/User/UserAllPermissions?username=' + username + '&role=' + role).then((res) => {
				let data = res.data.data;
				// data 是一个字典
				// 找到data中的key在switchesMapping中的对应项，然后将对应的value设置为true，并将switchesMapping中的键的值添加到activeNames中
				for (let key in data) {
					if (data[key] === true) {
						for (let key2 in this.switchesMapping) {
							if (key in this.switchesMapping[key2]) {
								this[this.switchesMapping[key2][key]] = true;
								this.activeNames.push(key2);
							}
						}
					}
				}
			}
			)
		}

	},
	methods: {
		handleSwitchChange(newValue, switchName) {
			console.log(`Switch ${switchName} changed to: ${newValue}`);
			this.$axios.post('/User/UpdatePermissions', {
				username: this.username,
				moduleCode: switchName,
				found: newValue,
			}).then((res) => {
				console.log(res);
			});
		}
	}
}

</script>

<style scoped>
.container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	/* 设置为垂直排列 */
	justify-content: flex-start;
	/* 从顶部开始排列 */
	align-items: center;
	/* 水平居中 */
}

.current-user {
	display: block;
	margin-bottom: 10px;
}

.box-card {
	width: 50%;
	margin: 0 auto;
}

.item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}</style>
<template>
    <div>

        <div class="buttons-container">
            <el-button type="primary" @click="download()">下载模板</el-button>


        </div>
        <el-upload class="upload-demo" drag action="" multiple :http-request="httpRequest" :file-list="fileList"
            :auto-upload="true" :before-upload="beforeFileUpload">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
        </el-upload>

    </div>
</template>
<script>
export default {
    data() {
        return {
            fileList: [],
            loadUrl: 'https://proteinuniverse.com.cn:9800/file/upload'
        };
    },
    methods: {
        download() {
            
            window.open("http://proteinuniverse.com.cn:9800/download");
        },
        beforeFileUpload(file) {
            const isCSV = file.type === 'text/csv';
            if (!isCSV) {
                this.$message.error('上传的文件格式不正确，请上传csv格式的文件!');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传的文件大小不能超过 2MB!');
                return false;
            }
            return true;
        },
        httpRequest(param) {
            let fileObj = param.file; // 相当于input里取得的files
            console.log(fileObj);
            let fd = new FormData(); // FormData 对象
            fd.append("sheetFile", fileObj); // 文件对象
            fd.append("nickname ", fileObj.name)

            let url = this.loadUrl
            console.log(url);
            this.$axios.post(url, fd).then((res) => {
                console.log(res);
                if (res.status == 200)
                    this.$message.success('上传成功');
                // 上传完成后，后台会将反馈结果反馈到这里，我们可以在这里进行接下来的一系列操作
                else
                    this.$message.error('上传失败');
            });
        },

    },

}
</script>

<style scoped>

.container {
    display: flex;
    margin-top: 20px;
}

.left-card {
    width: 200px;
    /* 占据1/3的空间 */
    /* 这里可以添加更多的样式，比如背景色、边框等 */
    margin-right: 20px;
}

.right-card {
    flex: 1;
    /* 占据2/3的空间 */
    /* 这里可以添加更多的样式，比如背景色、边框等 */
}



.buttons-container {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    justify-content: flex-start;
    /* 水平排列，从行首开始 */
    gap: 10px;
    /* 按钮之间的间距 */
}
</style>
<template>
    <div :style="{width: '100%' , height: pageHeight} ">
        <div>

            <div class="buttons-container">
                <el-button type="primary" @click="insert()">新增</el-button>
                <el-button type="primary" @click="download()">下载模板</el-button>

                <el-upload action="" :https-request="httpsRequest" :file-list="fileList" :auto-upload="true"
                    :before-upload="beforeFileUpload">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">请根据模板文件的格式进行上传，如果文件太大请分批上传</div>
                </el-upload>
            </div>


            <el-dialog title="新增" :visible.sync="editDialogVisible" :modal-append-to-body="false" :lock-scroll="true"
                width="80%" :style="{ maxHeight: '900vh', overflow: 'auto' }">
                <el-form ref="form" :model="form" label-width="113px" :inline="true">
                    <el-form-item :label-style="{ fontSize: '16px' }" v-for="(item, index) in Object.keys(form1)"
                        :key="index" :label="item">
                        <template v-if="isTextarea(item)">
                            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 40 }" placeholder="请输入内容"
                                v-model="form[form1[item]]" style="width: 200px;">
                            </el-input>
                        </template>
                        <template v-else>
                            <el-input v-model="form[form1[item]]" style="width: 200px;"></el-input>
                        </template>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sbummit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <div class="container">

            <!-- <div class="left-card"><el-card shadow="always">
                    <el-tree :data="data" show-checkbox node-key="id" :default-expanded-keys="[2, 3]"
                        :default-checked-keys="[5]" :props="defaultProps">
                    </el-tree> </el-card></div> -->
            <div class="right-card" >
                <el-card class="card" shadow="always" >
                    <el-table :data="tableData" :max-height="pageHeight" border>
                        <el-table-column prop="registrationNumber" label="登记号"></el-table-column>
                        <el-table-column prop="relatedRegistrationNumber" label="相关登记号"></el-table-column>
                        <el-table-column prop="drugName" label="药物名称"></el-table-column>
                        <el-table-column prop="indications" label="适应症">
                            <template slot-scope="scope">
                                <el-popover  placement="bottom" title="试验专业题目" width="750" height="1000" trigger="click"
                                    :content="scope.row.indications"
                                    :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                                    :open-delay="300">
                                    <el-button type="text" slot="reference" class="text-ellipsis">{{ scope.row.indications }}</el-button>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column prop="target" label="靶点"></el-table-column>
                        <el-table-column prop="contactName" label="联系人姓名"></el-table-column>
                        <el-table-column prop="combinationTherapy" label="方案是否为联合用药"></el-table-column>
                        <el-table-column prop="applicantName" label="申请人名称"></el-table-column>
                        <el-table-column prop="trialTtitle" label="试验专业题目">
                            <template slot-scope="scope">
                                <el-popover placement="bottom" title="试验专业题目" width="750" height="1000" trigger="click"
                                    :content="scope.row.trialTtitle"
                                    :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                                    :open-delay="300">
                                    <el-button type="text" slot="reference" class="text-ellipsis">{{ scope.row.trialTtitle }}</el-button>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column prop="firstPublicInformationDate" label="首次公示信息日期"></el-table-column>
                        <el-table-column prop="trialStatus" label="试验状态"></el-table-column>
                        <el-table-column prop="clinicalApplicationAcceptanceNumber" label="临床申请受理号"></el-table-column>
                        <el-table-column prop="sponsorInformation" label="申办单位信息">
                            <template slot-scope="scope">
                                <el-popover placement="bottom" title="申办单位信息" width="750" height="1000" trigger="click"
                                    :content="scope.row.sponsorInformation"
                                    :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                                    :open-delay="300">
                                    <el-button type="text" slot="reference" class="text-ellipsis">{{ scope.row.sponsorInformation }}</el-button>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column prop="trialPhase" label="试验分期"></el-table-column>
                        <el-table-column prop="trialStatusInformation" label="试验状态信息">
                            <template slot-scope="scope">
                                <el-popover placement="bottom" title="试验状态信息" width="750" height="1000" trigger="click"
                                    :content="scope.row.trialStatusInformation"
                                    :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                                    :open-delay="300">
                                    <el-button type="text" slot="reference" class="text-ellipsis">{{ scope.row.trialStatusInformation
                                    }}</el-button>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column prop="mainClinicalInstitutionInformation" label="主要临床机构信息">
                            <template slot-scope="scope">
                                <el-popover placement="bottom" title="主要临床机构信息" width="750" height="1000" trigger="click"
                                    :content="scope.row.mainClinicalInstitutionInformation"
                                    :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                                    :open-delay="300">
                                    <el-button type="text" slot="reference" class="text-ellipsis" >{{ scope.row.mainClinicalInstitutionInformation
                                    }}</el-button>
                                </el-popover>
                            </template>
                        </el-table-column>

                        <el-table-column prop="participatingClinicalInstitutionInformation" label="参与临床机构信息">
                            <template slot-scope="scope">
                                <el-popover placement="bottom" title="参与临床机构信息" width="750" height="1000" trigger="click"
                                    :content="scope.row.participatingClinicalInstitutionInformation"
                                    :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                                    :open-delay="300">
                                    <el-button type="text" slot="reference" class="text-ellipsis">{{
                                        scope.row.participatingClinicalInstitutionInformation }}</el-button>
                                </el-popover>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="20"
                        :total="maxTotal">
                    </el-pagination>
                </el-card>
            </div>
        </div>


        <el-dialog title="修改" :visible.sync="updateDialogVisible" :modal-append-to-body="false" :lock-scroll="true"
            width="80%" :style="{ maxHeight: '900vh', overflow: 'auto' }">
            <el-form ref="form" :model="form" label-width="113px" :inline="true">
                <el-form-item :label-style="{ fontSize: '16px' }" v-for="(item, index) in Object.keys(form1)" :key="index"
                    :label="item">
                    <template v-if="isTextarea(item)">
                        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 40 }" placeholder="请输入内容"
                            v-model="form[form1[item]]" style="width: 200px;">
                        </el-input>
                    </template>
                    <template v-else>
                        <el-input v-model="form[form1[item]]" style="width: 200px;"></el-input>
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="updateDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateDialogVisible = false">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import axios from 'axios';
export default {


    data() {
        return {
            tableData: [],
            fileList: [],
            data: [{
                id: 1,
                label: '一级 1',
                children: [{
                    id: 4,
                    label: '二级 1-1',
                    children: [{
                        id: 9,
                        label: '三级 1-1-1'
                    }, {
                        id: 10,
                        label: '三级 1-1-2'
                    }]
                }]
            }, {
                id: 2,
                label: '一级 2',
                children: [{
                    id: 5,
                    label: '二级 2-1'
                }, {
                    id: 6,
                    label: '二级 2-2'
                }]
            }, {
                id: 3,
                label: '一级 3',
                children: [{
                    id: 7,
                    label: '二级 3-1'
                }, {
                    id: 8,
                    label: '二级 3-2'
                }]
            }],
            form: {
                registrationNumber: '',
                relatedRegistrationNumber: '',
                drugName: '',
                indications: '',
                target: '',
                contactName: '',
                combinationTherapy: '',
                applicantName: '',
                trialTitle: '',
                firstPublicInformationDate: '',
                trialStatus: '',
                clinicalApplicationAcceptanceNumber: '',
                sponsorInformation: '',
                trialPhase: '',
                trialStatusInformation: '',
                mainClinicalInstitutionInformation: '',
                participatingClinicalInstitutionInformation: '',
            },
            form1: {
                "登记号": 'registrationNumber',
                "相关登记号": 'relatedRegistrationNumber',
                "药物名称": 'drugName',
                "适应症": 'indications',
                "靶点": 'target',
                "联系人姓名": 'contactName',
                "方案是否为联合用药": 'combinationTherapy',
                "申请人名称": 'applicantName',
                "试验专业题目": 'trialTitle',
                "首次公示信息日期": 'firstPublicInformationDate',
                "试验状态": 'trialStatus',
                "临床申请受理号": 'clinicalApplicationAcceptanceNumber',
                "申办单位信息": 'sponsorInformation',
                "试验分期": 'trialPhase',
                "试验状态信息": 'trialStatusInformation',
                "主要临床机构信息": 'mainClinicalInstitutionInformation',
                "参与临床机构信息": 'participatingClinicalInstitutionInformation'
            },
            textareaFields: ["主要临床机构信息", "参与临床机构信息", '试验专业题目', '靶点', '申请人名称', '申办单位信息'], // 假设这些字段使用textarea
            editDialogVisible: false,
            updateDialogVisible: false,
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            loadUrl: 'https://127.0.0.1:8000/upload1',
            maxTotal: 0,
            pageHeight: 0,
            ArrVisible:[], // 存放dialog的id
            windowWidth: window.innerWidth,  // 初始窗口宽度
        };
    },
    mounted() {
        Object.keys(this.form1).forEach((key) => {
            this.form[this.form1[key]] = '';
        });
    },
    created() {
        
        this.getdata();
        window.addEventListener('resize', this.handleResize);
        this.pageHeight = this.getPageHeight();
        // console.log(this.pageHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    
    watch:{
        // 监控页面高度变化
        windowWidth() {
            this.pageHeight = this.getPageHeight();
        }
    }

    ,
    methods: {
        handleResize() {
            // 只更新宽度数据属性
            this.windowWidth = window.innerWidth + window.innerHeight;
        },
        insert() {
            this.$nextTick(() => {
                this.form = {}
                this.editDialogVisible = true;
            });
        },
        sbummit() {
            // 显示提交成功

            axios.post("/informationEntryAndVerification/insert", this.form).then(res => {
                console.log(res);
                this.$message({
                    message: '提交成功',
                    type: 'success'
                });
            }).catch(err => {
                console.log(err);
                this.$message({
                    message: '提交失败',
                    type: 'error'
                });
                this.form = {
                    company: '',
                    companyInformation: '',
                    feedback: '',
                    customInformation: '',
                    creationTime: '',
                    contactInformation: '',
                    importBatch: '',
                    financingSituation: '',
                    allfinancingSituation: '',
                    incorporatingClues: '',
                    changes: '',
                    creators: '',
                    notes: '',
                };
            });


            this.editDialogVisible = false;
        },
        getdata() {
            axios.get("/informationEntryAndVerification/all" + '?pageNum=' + 1 + '&pageSize=20').then(res => {
                this.tableData = res.data.data;
                this.maxTotal = res.data.total
            }).catch(err => {
                console.log(err);
            });
        },
        isTextarea(fieldLabel) {
            return this.textareaFields.includes(fieldLabel);
        },
        download() {
            window.open("http://121.37.89.220:8000/download");
        },
        beforeFileUpload(file) {
            // console.log(file);
            const isCSV = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isCSV) {
                this.$message.error('上传的文件格式不正确，请上传csv格式的文件!');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传的文件大小不能超过 2MB!');
                return false;
            }
            return true;
        },
        httpsRequest(param) {
            let fileObj = param.file; // 相当于input里取得的files
            // console.log(fileObj);
            let fd = new FormData();
            fd.append("file", fileObj); // 文件对象
            let url = this.loadUrl
            this.$axios.post(url, fd).then((res) => {
                // console.log(res);
                if (res.data.status_code == 200)
                    this.$message.success('上传成功');
                // 上传完成后，后台会将反馈结果反馈到这里，我们可以在这里进行接下来的一系列操作
                else
                    this.$message.error('上传失败');
            }).catch(err => {
                this.$message.error(err);

            });
        },
        handleCurrentChange(page) {
            // this.tableData = [];
            axios.get('/informationEntryAndVerification/all' + '?pageNum=' + page + '&pageSize=20')
                .then((response) => {
                    // console.log(response);
                    this.tableData = response.data.data;
                    // console.log("翻页请求成功");
                }
                )

        },
        handleEdit(index, row) {
            // 修改表格数据的方法

            this.form = row;
            this.updateDialogVisible = true;
        },
        getPageHeight() {
            return (window.innerHeight || document.documentElement.scrollHeight || document.body.scrollHeight) / 10 * 9.2 - 120;
        }
    }
};



</script>

<style scoped >
.container {
    display: flex;
    margin-top: 20px;
}

.left-card {
    width: 200px;
    /* 占据1/3的空间 */
    /* 这里可以添加更多的样式，比如背景色、边框等 */
    margin-right: 20px;
}

.right-card {
    flex: 1;
    /* 占据2/3的空间 */
    /* 这里可以添加更多的样式，比如背景色、边框等 */

}

.card {
    overflow-x: clip;
}
.text-ellipsis {
  display: inline-block;
  max-width: 120px; /* 可以根据需要调整宽度 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttons-container {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    justify-content: flex-start;
    /* 水平排列，从行首开始 */
    gap: 10px;
    /* 按钮之间的间距 */
}
</style>
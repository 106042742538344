<template>
    <div>
        <div class="button-container">
            <el-button class="button-margin" type="success" @click="getdata">新酶或突变体统计</el-button>
            <el-button class="button-margin" type="success" @click="getdata2">新酶或突变体反应数据</el-button>
        </div>
        <br>
        <div class="input-container">
            <el-input placeholder="请输入内容" v-model="filterQuery" class="input-with-select">
                <el-select v-model="filterQueryHeader" filterable slot="prepend" style="width: 150px;"
                    placeholder="请选择">
                    <el-option v-for="(label, prop) in tempForm" :key="prop" :label="label" :value="prop"></el-option>
                </el-select>
                <el-button slot="append" @click="addFilter">添加搜索条件</el-button>
                <el-button slot="append" icon="el-icon-search" @click="filterData"></el-button>
            </el-input>
        </div>
        <div style="height: 40px;" class="input-container">
            <el-tag v-for="(filter, index) in filters" :key="index" closable @close="removeFilter(index)"
                style="margin: 5px;">
                '{{ filter.key }}' 包含 '{{ filter.value }}''
            </el-tag>
            <el-button type="danger" @click="clearFilter" v-show="filters.length">清除所有筛选条件</el-button>
        </div>

        <el-table :data="tableData" style="width: 100%" border v-show="show1" :max-height="pageHight">

            <template v-for="(label, prop) in form1">
                <el-table-column
                    v-if="!isReactionSystemColumn(prop) && !isReactionConditionColumn(prop) && !isEeColumn(prop) && !isConversionRateColumn(prop) && !isRemarksColumn(prop)"
                    :key="prop" :prop="prop" :label="label" :class="getClassName(prop)"
                    :width="getColumnWidth(prop)" sortable ></el-table-column>
            </template>

            <el-table-column>

                <template slot="header">
                    <div class="reaction-system-header">反应体系</div>
                </template>

                <template v-for="(label, prop) in form1">
                    <el-table-column v-if="isReactionSystemColumn(prop)" :key="prop" :prop="prop"
                        :label="label"
                        :width="getColumnWidth(prop)" sortable></el-table-column>
                </template>
            </el-table-column>

            <el-table-column>

                <template slot="header">
                    <div class="reaction-system-header">反应条件</div>
                </template>

                <template v-for="(label, prop) in form1">
                    <el-table-column v-if="isReactionConditionColumn(prop)" :key="prop" :prop="prop"
                        :label="label" :width="getColumnWidth(prop)" sortable></el-table-column>
                </template>
            </el-table-column>

            <el-table-column>

                <template slot="header">
                    <div class="reaction-system-header">转化率</div>
                </template>

                <template v-for="(label, prop) in form1">
                    <el-table-column v-if="isConversionRateColumn(prop)" :key="prop" :prop="prop" :label="label"
                        :width="getColumnWidth(prop)" sortable></el-table-column>
                </template>
            </el-table-column>

            <el-table-column>

                <template slot="header">
                    <div class="reaction-system-header">ee值</div>
                </template>

                <template v-for="(label, prop) in form1">
                    <el-table-column v-if="isEeColumn(prop)" :key="prop" :prop="prop" :label="label"
                        :width="getColumnWidth(prop)" sortable></el-table-column>
                </template>
            </el-table-column>

            <template v-for="(label, prop) in form1">
                <el-table-column v-if="isRemarksColumn(prop)" :key="prop" :prop="prop" :label="label"
                    :width="getColumnWidth(prop)" sortable></el-table-column>
            </template>



        </el-table>
        <el-table :data="tableData" style="width: 100%" border v-show="!show1" :max-height="pageHight">
                <template v-for="(label, prop) in form2">
                    <el-table-column v-if="isProjectContentColumn(prop)" :key="prop" :prop="prop"
                        :label="label" sortable  :width="getColumnWidth(prop)"></el-table-column>
                </template>


        </el-table>


    </div>
</template>

<script>

export default {

    data() {
        return {
            filters: [],
            tableData: [],
            tempTableData: [],
            updateDialogVisible: false,
            filterQuery: '',
            filterQueryHeader: '',//对哪一栏进行过滤
            form1: {
                "enzymeId": "酶编号",
                "strainId": "菌株编号",
                "enzymeType": "酶类型",
                "seqSource": "序列来源",
                "literature": "文献",
                "NCBIID": "NCBI序列号",
                "aminoAcidSeq": "氨基酸序列",
                "nucleicAcidSeq": "优化后的核酸序列",
                "mutationSite": "突变位点",
                "bp": "基因（bp）",
                "aa": "氨基酸（aa）",
                "carrier": "载体",
                "pointOfEnzymeCleavage": "酶切位点",
                "antibody": "抗性",
                "kDa": "酶分子量（kDa）",
                "IPTGConcentration": "IPTG浓度（mM）",
                "temperatureOfInduction": "诱导温度(℃)",
                "timeOfInduction": "诱导时间（h）",
                "plasmid": "质粒（有无）",
                "DH5aGlycerolGroup": "DH5a甘油菌（有无）",
                "BL21GlycerolGroup": "BL21甘油菌群(有无)",
                "proteinImage": "蛋白图",
                "remarks1": "备注",
                "projectPerson": "项目人",
                "substrate": "底物（名称+用量）",
                "enzymeAmount": "酶量（浓度+用量）",
                "buffer": "buffer（名称+浓度+用量）",
                "otherMaterials1": "其它物料1（名称+用量）",
                "otherMaterials2": "其它物料2（名称+用量）",
                "otherMaterials3": "其它物料3（名称+用量）",
                "otherMaterials4": "其它物料4（名称+用量）",
                "otherMaterials5": "其它物料5（名称+用量）",
                "otherMaterials6": "其它物料6（名称+用量）",
                "otherMaterials7": "其它物料7（名称+用量）",
                "otherNotes": "其它说明",
                "temp": "温度",
                "pH": "pH值",
                "rpm": "转速（rpm）",
                "time": "时间（h）",
                "conversionRate": "转化率（%）",
                "samplingAndDeliveryMode1": "取样送样方法",
                "timeOfPeak": "产品/底物/杂志出峰时间",
                "atlas1": "图谱（有无）",
                "ee": "e.e值（%）",
                "samplingAndDeliveryMode2": "取样送样方法",
                "peakTimeOfRacemate": "消旋体出峰时间",
                "atlas2": "图谱（有无）",
                "specialAttentionSteps": "投反过程特别注意的地方",
                "remarks2": "备注"
            },
            form2: {
                "roundsID": "第几轮",
                "enzymeId": '酶编号',
                "template": "模板",
                "evolveMethod": "进化方法",
                "mutationSite": "突变位点",
                "strainNumber": "菌株编号",
                "date": "试验日期",
                "reactionCondition": "反应条件",
                "sampleNumber": "样品编号",
                "reactionTime": "反应时间（h）",
                "conversionRateDetectionMethod": "转化率检测方法",
                "product": "产品",
                "substrate": "底物（分子量大）",
                "impurity": "杂质",
                "conversionRate": "转化率%",
                "chiralityDetectionMethod": '手性检测方法',
                "eeValue": 'ee值',
                "remark": '备注',
                "drugs": "药物",
                "s": 'S',
                "r": 'R'
            },
            form: {},
            search: '',
            show1: true,
            show2: false,
            select: '',
            tempForm: {},
            pageHight: 0
        }
    },
    created() {
        this.pageHight = this.getPageHeight()
        this.getdata()
    },
    mounted() {
        window.addEventListener('resize', this.updateTableHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateTableHeight);
    },
    methods: {

        /* eslint-enable */
        getPageHeight() {
            // 获取页面高度
            return (window.innerHeight || document.documentElement.scrollHeight || document.body.scrollHeight) / 10 * 9.2 - 180;
        },
        updateTableHeight() {
            this.pgaeHeight = this.getPageHeight();
        },
        addFilter() {
            console.log(this.filterQueryHeader, this.filterQuery);
            if (this.filterQuery && this.filterQueryHeader) {
                this.filters.push({
                    key: this.tempForm[this.filterQueryHeader],
                    value: this.filterQuery,
                });
                this.filterQuery = ''; // 清空输入框s
            }
        },
        removeFilter(index) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.filters.splice(index, 1);
            if (this.filters.length == 0) {
                this.tableData = this.tempTableData;
            }
            loading.close();
        },
        filterData() {

            if (this.filters.length == 0) {
               this.$message.error('请先添加筛选条件');
                return;
            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            
            // 在这里执行筛选数据的逻辑，可能需要调用API或更新视图
            // 初始化一个对象来存储每个筛选键对应的一个或多个筛选值
            const filterMap = {};
            this.filters.forEach(filter => {
                // 获取每个筛选条件的键，通过form1对象转换得到实际的数据键
                const key = this.getKeyByValue(this.tempForm, filter.key);


                // 如果filterMap中尚未有这个键，初始化一个空数组
                if (!filterMap[key]) {
                    filterMap[key] = [];
                }
                // 将筛选值转换为小写并添加到对应键的数组中
                filterMap[key].push(filter.value.toLowerCase());
            });
            console.log(filterMap);
            // 使用filter方法筛选tableData中的行，保留满足所有筛选条件的行
            this.tableData = this.tempTableData.filter(row => {
                // 对filterMap中的每个键进行迭代
                return Object.keys(filterMap).every(key => {
                    // 检查当前行的该键的值是否满足某个筛选条件（'或'关系）
                    // 即，只要行的该键的值匹配filterMap中该键对应的任一值，就返回true
                    return filterMap[key].some(filterValue => {
                        // 将行的值转换为小写，并检查是否包含筛选值
                        return String(row[key]).toLowerCase().includes(filterValue);
                    });
                });
            });
            loading.close();

        },
        clearFilter() {
            // 清除筛选条件的逻辑
            this.filters = [];
            this.tableData = this.tempTableData;
        },
        // 根据字典的值获取字典的键
        getKeyByValue(obj, value) {
            return Object.keys(obj).find(key => obj[key] === value);
        },
        getdata() {
            localStorage.getItem('token');
            this.$axios.get('/EnzymeDB/EntityEnzymeStatistics').then(res => {
                this.tableData = res.data.data
                this.tempTableData = res.data.data
                this.show1 = true
                this.show2 = false
                this.tempForm = this.form1
            }).catch(err => {
                console.log(err)
            })

        },
        getdata2() {
            this.$axios.get('/EnzymeDB/EntityEnzymeReaction').then(res => {
                this.tableData = res.data.data
                this.tempTableData = res.data.data
                this.show1 = false
                this.show2 = true
                this.tempForm = this.form2
            }).catch(err => {
                console.log(err)
            })
        },
        // 根据列的属性名称返回类名
        getClassName(prop) {
            // 根据列的属性名称返回类名
            if (prop.includes('samplingAndDeliveryMode')) {
                return 'Sampling-and-sending-samples';
            }
            return '';
        },
        getColumnWidth(prop) {
            // 根据列的属性名称设置宽度
            const arry200 = ['samplingAndDeliveryMode1', 'samplingAndDeliveryMode2','seqSource','timeOfPeak']
            if (arry200.includes(prop)) {
                return '280';
            }

            return '180';
        },
        // tableData的表头
        // 反应体系
        isReactionSystemColumn(prop) {
            const reactionSystemColumns = [
                'substrate', 'enzymeAmount', 'buffer',
                'otherMaterials1', 'otherMaterials2', 'otherMaterials3',
                'otherMaterials4', 'otherMaterials5', 'otherMaterials6', 'otherMaterials7', 'otherNotes'
            ];
            return reactionSystemColumns.includes(prop);
        },
        // 反应条件
        isReactionConditionColumn(prop) {
            const reactionConditionColumns = [
                'temp', 'pH', 'rpm', 'time',
            ];
            return reactionConditionColumns.includes(prop);
        },
        // ee值
        isEeColumn(prop) {
            const eeColumns = [
                'ee', 'samplingAndDeliveryMode1', 'peakTimeOfRacemate', 'atlas2'
            ];
            return eeColumns.includes(prop);
        },
        //转化率
        isConversionRateColumn(prop) {
            const conversionRateColumns = [
                'conversionRate', 'samplingAndDeliveryMode1', 'timeOfPeak', 'atlas1'
            ];
            return conversionRateColumns.includes(prop);
        },
        // 备注
        isRemarksColumn(prop) {
            const remarksColumns = [
                'specialAttentionSteps', 'remarks2'
            ];
            return remarksColumns.includes(prop);
        },
        // tableData2的表头
        // 项目内容
        isProjectContentColumn(prop) {
            const projectContentColumns = [
                "roundsID",
                "enzymeId",
                "template",
                "drugs",
                "evolveMethod",
                "mutationSite",
                "strainNumber",
                "date",
                "reactionCondition",
                "sampleNumber",
                "reactionTime",
                "conversionRateDetectionMethod",
                "product",
                "substrate",
                "impurity",
                "conversionRate",
                "chiralityDetectionMethod",
                "eeValue",
                "remark",
                "s",
                "r"
            ];
            return projectContentColumns.includes(prop);
        },

        updateTableRow() {
            this.updateDialogVisible = true;
        },


    }
}
</script>

<style scoped>
.reaction-system-header {
    background-color: #3399ff;
    /* 示例背景色 */
    color: white;
    /* 示例文字色 */
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    /* 文本居中 */
    width: 100%;
    /* 确保div填充父容器的宽度 */
}

.reaction-system-ProjectContentColumn-header {
    background-color: #747677;
    /* 示例背景色 */
    color: white;
    /* 示例文字色 */
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    /* 文本居中 */
    width: 100%;
    /* 确保div填充父容器的宽度 */
}

.reaction-system-SolidMaterialA-header,
.reaction-system-SolidMaterialB-header,
.reaction-system-SolidMaterialC-header,
.reaction-system-SolidMaterialD-header,
.reaction-system-SolidMaterialF-header,
.reaction-system-SolidMaterialE-header {
    background-color: #0aaafa;
    /* 示例背景色 */
    color: white;
    /* 示例文字色 */
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    /* 文本居中 */
    width: 100%;
    /* 确保div填充父容器的宽度 */
}


.reaction-system-LiquidMaterialA-header,
.reaction-system-LiquidMaterialC-header,
.reaction-system-LiquidMaterialB-header {
    background-color: #0aaafa;
    /* 示例背景色 */
    color: white;
    /* 示例文字色 */
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    /* 文本居中 */
    width: 100%;
    /* 确保div填充父容器的宽度 */
}

.reaction-system-ReactionConditionColumnTable-header {
    /* background-color: #eaeef0; */
    /* 示例背景色 */
    /* color: white; */
    /* 示例文字色 */
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    /* 文本居中 */
    width: 100%;
    /* 确保div填充父容器的宽度 */

}

.reaction-system-ConversionRateColumnTable-header {
    background-color: #92d821;
    /* 示例背景色 */
    color: white;
    /* 示例文字色 */
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    /* 文本居中 */
    width: 100%;
    /* 确保div填充父容器的宽度 */
}

.reaction-system-ConversionRateDataAnalysis-header {
    background-color: #98f306;
    /* 示例背景色 */
    color: white;
    /* 示例文字色 */
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    /* 文本居中 */
    width: 100%;
    /* 确保div填充父容器的宽度 */
}

.reaction-system-EeColumn-header {
    background-color: #65b662;
    /* 示例背景色 */
    color: white;
    /* 示例文字色 */
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    /* 文本居中 */
    width: 100%;
    /* 确保div填充父容器的宽度 */
}

.reaction-system-EeDataAnalysis-header {
    background-color: #98f306;
    /* 示例背景色 */
    color: white;
    /* 示例文字色 */
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    /* 文本居中 */
    width: 100%;
    /* 确保div填充父容器的宽度 */
}

.button-container {
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中（如果需要） */
}

.button-margin {
    margin-bottom: 20px;
    margin-right: 10px;
    margin-top: 20px;
}

.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* 距离下方 5px */
    margin-bottom: 5px;

}

.input-with-select {
    width: 40%;
}

.Sampling-and-sending-samples {
    width: 200px;
}
</style>
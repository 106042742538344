<template>
    <div>
        <el-table :data="tableData" border style="width: 100%" :max-height="pageHeight">

            <el-table-column type="expand">
                <template slot-scope="props">

                    <!-- 嵌套表格 -->
                    <template>
                        <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left: 20px;">
                            <el-tab-pane label="hits" name="hits"></el-tab-pane>
                            <el-tab-pane label="conditions" name="conditions"></el-tab-pane>
                        </el-tabs>
                    </template>

                    <el-table v-if="showHits" :data="props.row.hits" style="width: 100%;margin-left: 20px;" border
                        class="table-expand">
                        <el-table-column prop="conditions" label="Condition"></el-table-column>
                        <el-table-column prop="yield" label="Yield"></el-table-column>
                        <el-table-column prop="reference.authors" label="Authors"></el-table-column>
                        <el-table-column prop="reference.literature" label="literature"></el-table-column>
                        <el-table-column prop="reference.location-in-patent"
                            label="location-in-patent"></el-table-column>
                    </el-table>

                    <el-table v-else :data="props.row.conditions" style="width: 100%;margin-left: 20px;" border
                        class="table-expand">
                        <el-table-column prop="conditions" label="Condition"></el-table-column>
                        <el-table-column prop="yield" label="Yield"></el-table-column>
                        <el-table-column prop="reference.authors" label="Authors"></el-table-column>
                        <el-table-column prop="reference.literature" label="literature"></el-table-column>
                        <el-table-column prop="reference.location-in-patent"
                            label="location-in-patent"></el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column header-align="center" align="center" prop="enName" label="酶英文名" width="200px">
            </el-table-column>
            <el-table-column header-align="center" align="center" prop="chName" label="酶中文名" width="200px">
            </el-table-column>
            <el-table-column label="反应式">
                <template slot-scope="scope">
                    <div class="reaction-format">
                        <!-- 底物展示部分 -->
                        <div class="item" v-if="scope.row.sub1Img || scope.row.sub1Name">
                            <img v-if="scope.row.sub1Img" :src="scope.row.sub1Img" alt="Sub1 Image" class="reaction-img" @click="openImg(scope.row.sub1Img)" style="cursor: pointer;" />
                            <span v-if="scope.row.sub1Name" @click="jumpToCompound(scope.row.sub1Name)" class="hover-pointer">{{ scope.row.sub1Name }}</span>
                        </div>
                        <span v-if="(scope.row.sub1Img && scope.row.sub2Img) || (scope.row.sub1Name && scope.row.sub2Name)"> + </span>
                        <div class="item" v-if="scope.row.sub2Img || scope.row.sub2Name">
                            <img v-if="scope.row.sub2Img" :src="scope.row.sub2Img" alt="Sub2 Image" class="reaction-img" @click="openImg(scope.row.sub2Img)" style="cursor: pointer;" />
                            <span v-if="scope.row.sub2Name" @click="jumpToCompound(scope.row.sub2Name)" class="hover-pointer">{{ scope.row.sub2Name }}</span>
                        </div>
                        <span v-if="(scope.row.sub2Img && scope.row.sub3Img) || (scope.row.sub2Name && scope.row.sub3Name)"> + </span>
                        <div class="item" v-if="scope.row.sub3Img || scope.row.sub3Name">
                            <img v-if="scope.row.sub3Img" :src="scope.row.sub3Img" alt="Sub3 Image" class="reaction-img" @click="openImg(scope.row.sub3Img)" style="cursor: pointer;" />
                            <span v-if="scope.row.sub3Name" @click="jumpToCompound(scope.row.sub3Name)" class="hover-pointer">{{ scope.row.sub3Name }}</span>
                        </div>
                        <span class="arrow"> => </span>
                        <!-- 产物展示部分 -->
                        <div class="item" v-if="scope.row.product1Img || scope.row.product1Name">
                            <img v-if="scope.row.product1Img" :src="scope.row.product1Img" alt="Product1 Image" class="reaction-img" @click="openImg(scope.row.product1Img)" style="cursor: pointer;" />
                            <span v-if="scope.row.product1Name" @click="jumpToCompound(scope.row.product1Name)" class="hover-pointer">{{ scope.row.product1Name }}</span>
                        </div>
                        <span v-if="(scope.row.product1Img && scope.row.product2Img) || (scope.row.product1Name && scope.row.product2Name)"> + </span>
                        <div class="item" v-if="scope.row.product2Img || scope.row.product2Name">
                            <img v-if="scope.row.product2Img" :src="scope.row.product2Img" alt="Product2 Image" class="reaction-img" @click="openImg(scope.row.product2Img)" style="cursor: pointer;" />
                            <span v-if="scope.row.product2Name" @click="jumpToCompound(scope.row.product2Name)" class="hover-pointer">{{ scope.row.product2Name }}</span>
                        </div>
                        <span v-if="(scope.row.product2Img && scope.row.product3Img) || (scope.row.product2Name && scope.row.product3Name)"> + </span>
                        <div class="item" v-if="scope.row.product3Img || scope.row.product3Name">
                            <img v-if="scope.row.product3Img" :src="scope.row.product3Img" alt="Product3 Image" class="reaction-img" @click="openImg(scope.row.product3Img)" style="cursor: pointer;" />
                            <span v-if="scope.row.product3Name" @click="jumpToCompound(scope.row.product3Name)" class="hover-pointer">{{ scope.row.product3Name }}</span>
                        </div>
                    </div>

                </template>

            </el-table-column>

        </el-table>
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage3" :page-size="20"
                layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
            <button>
                数据有误,点击上报（暂未完成）

            </button>
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            tempForm: {
                // reactionId: "反应ID",
                enName: "酶英文名",
                chName: "酶中文名",
                conditions: "反应条件",
                sub1Name: "反应物1名称",
                sub1Formula: "反应物1分子式",
                sub1Cas: "反应物1 CAS 号",
                sub1Img: "反应物1图像",
                sub2Name: "反应物2名称",
                sub2Formula: "反应物2分子式",
                sub2Cas: "反应物2 CAS 号",
                sub2Img: "反应物2图像",
                sub3Name: "反应物3名称",
                sub3Formula: "反应物3分子式",
                sub3Cas: "反应物3 CAS 号",
                sub3Img: "反应物3图像",
                product1Name: "产物1名称",
                pro1Formula: "产物1分子式",
                product1Cas: "产物1 CAS 号",
                product1Img: "产物1图像",
                product2Name: "产物2名称",
                product2Formula: "产物2分子式",
                product2Cas: "产物2 CAS 号",
                product2Img: "产物2图像",
                product3Name: "产物3名称",
                product3Formula: "产物3分子式",
                product3Cas: "产物3 CAS 号",
                product3Img: "产物3图像",
                hits: "搜索结果",
                // datetime: "收录时间",
            },
            additionalRows: ['someProp'],
            activeName: 'hits', // 控制table展开的tab
            showHits: true,
            pageHeight: 0,
            currentPage3: 1,
            total: 0,
            value: '',

        };
    },
    computed: {
        filteredColumns() {
            return Object.keys(this.tempForm).reduce((acc, key) => {
                // 排除不应独立成列的属性
                if (!key.includes('sub1Name') && !key.includes('sub2Name') && !key.includes('sub3Name') && !key.includes('product1Name') && !key.includes('product2Name') && !key.includes('product3Name') && !key.includes('Formula') && !key.includes('Cas') && !key.includes('conditions') && !key.includes('hits')) {
                    console.log(key);

                    acc[key] = this.tempForm[key];
                }
                console.log(acc);

                return acc;
            }, {});
        }
    },
    created() {

        if (this.$route.query.value) {
            this.value = this.$route.query.value;
            this.$axios.post('/ReaxysDB/ReaxysReactionByName', {
                Value: this.value,
                page: this.$route.query.page
            }).then(res => {
                let data = res.data.data;
                if (res.data.code === 500) {
                    this.$message({
                        message: '没有更多数据了,请检查页数是否正确',
                        type: 'warning'
                    });
                    return;
                }
                for (let i = 0; i < data.length; i++) {
                    if (data[i].hits !== undefined && data[i].hits !== null && typeof data[i].hits === 'string') {
                        data[i].hits = JSON.parse(data[i].hits);
                    }

                    // 检查 conditions 是否不为 null，且确保其为字符串
                    if (data[i].conditions !== null && typeof data[i].conditions === 'string') {
                        data[i].conditions = JSON.parse(data[i].conditions);
                        // 去掉 conditions 中的 conditions 为 "Show Less"的
                        data[i].conditions = data[i].conditions.filter(item => item.conditions !== 'Show Less');

                    }
                }
                this.total = res.data.total;
                this.tableData = data;
                console.log(this.tableData);
            }

            )
        }
        else {
            let page = localStorage.getItem("reaxysPage") ? this.currentPage3 = parseInt(localStorage.getItem("reaxysPage")) : this.currentPage3 = 1;
            this.$axios.post('/ReaxysDB/ReaxysReaction', {
                page: page,
            }).then(res => {
                let data = res.data.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].hits !== undefined && data[i].hits !== null && typeof data[i].hits === 'string') {
                        data[i].hits = JSON.parse(data[i].hits);
                    }

                    // 检查 conditions 是否不为 null，且确保其为字符串
                    if (data[i].conditions !== null && typeof data[i].conditions === 'string') {
                        data[i].conditions = JSON.parse(data[i].conditions);
                        // 去掉 conditions 中的 conditions 为 "Show Less"的
                        data[i].conditions = data[i].conditions.filter(item => item.conditions !== 'Show Less');

                    }
                }
                this.total = res.data.total;
                this.tableData = data;
                console.log(this.tableData);



            });
        }


        this.pageHeight = this.getPageHeight();

    }, methods: {

        jumpToCompound(name) {
          // 跳转到化合物库

          this.$axios.post('/CompoundDB/CompoundSearch', { value: name }).then((res) => {
            if (res.data.status != '200') {
              this.$message.error(res.data.msg);
              return;
            } else if (res.data.data.length == 0) {
              this.$message.error('没有数据');
              return;
            }
            let data = res.data.data;

            localStorage.setItem("CompoundData", JSON.stringify(data));
            let routeURL = this.$router.resolve({ name: '化合物库', query: { keyword: name } }).href;
            window.open(routeURL, '_blank');
          })

        },

        handleClick() {
            if (this.activeName === 'hits') {
                this.showHits = true;
            } else if (this.activeName === 'conditions') {
                this.showHits = false;
            }
        },
        getColumnClass(label) {
            if (label.includes('反应物')) {
                return 'reaction-column';
            } else if (label.includes('产物')) {
                return 'product-column';
            } else {
                return ''; // 默认无特别样式
            }
        },
        getPageHeight() {
            // 获取页面高度
            return (window.innerHeight || document.documentElement.scrollHeight || document.body.scrollHeight) / 10 * 9.2 - 40;
        },
        // 分页

        handleCurrentChange(val) {
            if (this.value == '') {
                this.$axios.post('/ReaxysDB/ReaxysReaction', {
                    page: this.currentPage3,
                }).then(res => {
                    let data = res.data.data;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hits !== undefined && data[i].hits !== null && typeof data[i].hits === 'string') {
                            data[i].hits = JSON.parse(data[i].hits);
                        }

                        // 检查 conditions 是否不为 null，且确保其为字符串
                        if (data[i].conditions !== null && typeof data[i].conditions === 'string') {
                            data[i].conditions = JSON.parse(data[i].conditions);
                            // 去掉 conditions 中的 conditions 为 "Show Less"的
                            data[i].conditions = data[i].conditions.filter(item => item.conditions !== 'Show Less');

                        }
                    }
                    this.total = res.data.total;
                    this.tableData = data;



                });
            }
            else {
                this.$axios.post('/ReaxysDB/ReaxysReactionByName', {
                    Value: this.value,
                    page: this.currentPage3
                }).then(res => {


                    let data = res.data.data;
                    if (data.length === 0) {
                        this.$message({
                            message: '没有更多数据了',
                            type: 'warning'
                        });
                        return;

                    }
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hits !== undefined && data[i].hits !== null && typeof data[i].hits === 'string') {
                            data[i].hits = JSON.parse(data[i].hits);
                        }

                        // 检查 conditions 是否不为 null，且确保其为字符串
                        if (data[i].conditions !== null && typeof data[i].conditions === 'string') {
                            data[i].conditions = JSON.parse(data[i].conditions);
                            // 去掉 conditions 中的 conditions 为 "Show Less"的
                            data[i].conditions = data[i].conditions.filter(item => item.conditions !== 'Show Less');

                        }
                    }
                    this.tableData = data;
                    this.total = res.data.total;
                    this.currentPage3 = val;
                    localStorage.setItem("reaxysPage", val);

                });
            }



        }
    },
    mounted() {
        // 组件挂载时计算页面高度
        this.pageHeight = this.getPageHeight();
        // 可以添加事件监听器来实时更新页面高度
        window.addEventListener('resize', () => {
            this.pageHeight = this.getPageHeight();
        });
    },
    beforeDestroy() {
        // 组件销毁前移除事件监听器
        window.removeEventListener('resize', this.getPageHeight);
    },
}
</script>

<style scoped>
.table-expand-container {
    display: flex;
    justify-content: space-between;
    /* 确保两个表格之间有一定间隔 */
    align-items: flex-start;
    /* 对齐表格到容器的顶部 */
    width: 100%;
    /* 容器宽度为100% */
    flex-wrap: nowrap;
    /* 防止表格换行 */
    overflow-x: auto;
    /* 允许横向滚动 */
}

.table-expand {
    flex: 1 1 49%;
    /* 使用flex布局，允许每个表格占据49%的空间 */
    margin-right: 1%;
    /* 右边距为1% */
    box-sizing: border-box;
    /* 边框盒模型，边框和内边距包含在宽度内 */
}

.table-expand:last-child {
    margin-right: 0;
    /* 最后一个表格没有右边距 */
}

.reaction-column {
    background-color: #cef5d5;
    /* 粉红色背景 */
}

.product-column {
    background-color: #ce95f0;
    /* 浅绿色背景 */
}
.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;  /* 确保项与项之间有间隔 */
}

.arrow {
    margin: 0 5px;
}
.reaction-format {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
</style>
<template>
    <div>
        公司名称: {{ companyName }}
        <div>
            <el-card class="box-card">

            </el-card>

            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>隐藏信息</span>
                </div>
                目标客户信息及分类
                <el-descriptions title="客户信息">
                    <el-descriptions-item label="公司">{{companyName}}</el-descriptions-item>
                    <el-descriptions-item label="省/市">{{region}}</el-descriptions-item>
                    <el-descriptions-item label="城市">{{city}}</el-descriptions-item>
                    <el-descriptions-item label="统一社会信用代码">{{creditCode}}</el-descriptions-item>
                    <el-descriptions-item label="所在区县(或省级/市级)">{{detailedCity}}</el-descriptions-item>
                    <el-descriptions-item label="成立日期">{{cretedDate}}</el-descriptions-item>
                    <el-descriptions-item label="网站">{{website}}</el-descriptions-item>
                    <el-descriptions-item label="网站">{{website}}</el-descriptions-item>
                    <el-descriptions-item label="网站">{{website}}</el-descriptions-item>
                    <el-descriptions-item label="描述">{{describe}}</el-descriptions-item>
                    <el-descriptions-item label="经营范围">{{businessScope}}</el-descriptions-item>
                </el-descriptions>
            </el-card>
        </div>
    </div>
</template>


<script>

export default {
    data() {
        return {
            companyName: '',
            region: '',
            city: '',
            creditCode:'',
            detailedCity:"",
            cretedDate:"",
            website:"",
            describe:"",
            businessScope:"",
        };
    },
};
</script>
<template>
    <div class="container">
        <div class="content">
            <el-tabs type="border-card" class="custom-tabs" @tab-click="handleClick">
                <el-tab-pane label="Description">

                    <el-button type="primary" @click="exportExcel">数据导出为Excel</el-button>
                    <div class="tab-content">
                        <template>
                            <el-table :data="DescriptionTableData" :height="pgaeHeight" border style="width: 100%" @selection-change="handleSelectionChange">
                                <el-table-column
                                  type="selection"
                                  width="55">
                                </el-table-column>
                                <el-table-column prop="Description" label="Description" width="240"
                                    sortable></el-table-column>
                                <el-table-column prop="Acc len" label="Acc len" width="180" sortable>
                                </el-table-column>
                                <el-table-column prop="Accession" label="Accession" width="180" sortable>
                                </el-table-column>

                                <el-table-column prop="E value" label="E value" sortable></el-table-column>
                                <el-table-column prop="Max Score" label="Max Score" sortable></el-table-column>
                                <el-table-column prop="Per Ident" label="Per Ident(%)" sortable></el-table-column>
                                <el-table-column prop="Scientific Name" label="Scientific Name"
                                    sortable></el-table-column>
                                <el-table-column prop="Query Cover" label="Query Cover(%)" sortable></el-table-column>
                                <el-table-column prop="Total Score" label="Total Score" sortable></el-table-column>
                            </el-table>
                        </template>


                    </div>
                </el-tab-pane>
                <el-tab-pane label="Alignments">
                    <div class="tab-content">
                        <el-button type="primary" @click="exportExcel1">数据导出为Excel</el-button>
                        <br>
                        <template>
                            <el-card class="box-card" v-for="(card, index) in AlignmentsTableData" :key="index">
                                <div slot="header" class="clearfix">
                                    <span></span>
                                    <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                                </div>
                                <div>

                                    <span class="sequence">
                                        {{ card.title }}
                                        <br>
                                        sequence ID: {{ card['sequence ID'] }} &nbsp; Length : {{ card.Length }} &nbsp;

                                    </span>
                                    <template>

                                        <el-table :data="card.tableData" style="width: 100%">
                                            <el-table-column prop="Score" label="Score" width="180">
                                            </el-table-column>
                                            <el-table-column prop="Method" label="Method" width="180">
                                            </el-table-column>
                                            <el-table-column prop="Identities" label="Identities">
                                            </el-table-column>
                                            <el-table-column prop="Positives" label="Positives">
                                            </el-table-column>
                                            <el-table-column prop="Gaps" label="Gaps">
                                            </el-table-column>

                                        </el-table>
                                        <br>
                                        <span class="sequence">
                                            {{ card.Query }}
                                        </span>
                                        <span class="sequence">
                                            {{card.result}}
                                        </span>
                                        <br>
                                        <span class="sequence">
                                            {{ card.Sbjct }}
                                        </span>



                                    </template>
                                </div>
                            </el-card>
                        </template>


                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
    data() {
        return {
            DescriptionTableData: [],
            AlignmentsTableData: [],
            pgaeHeight: 0,
            multipleSelection: [],//多选列表
            
         }
    },
    created() {
        this.pgaeHeight = this.getPageHeight();
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$axios.post('/ProteinSequenceSearch', {
            sequence: this.$route.params.data
        }).then(res => {
            this.DescriptionTableData = res.data.data.Description;
            this.AlignmentsTableData = res.data.data.Alignments;
            // 处理AlignmentsTableData的内容
            for (let index = 0; index < this.AlignmentsTableData.length; index++) {
                const element = this.AlignmentsTableData[index];
                let itemTableData = [];
                let item = {
                    'Score': element.Score.replace(/ Score = /g, ""),
                    'Method': element.Method.replace(/Method: /g, ""),
                    'Identities': element.Identities.replace(/ Identities = /g, ""),
                    'Positives': element.Positives.replace(/Positives =/g, ""),
                    'Gaps': element.Gaps.replace(/Gaps =/g, "")

                }
                itemTableData.push(item);
                this.AlignmentsTableData[index].tableData = itemTableData;
            }
        }).catch(err => {
            console.log(err);
            this.$message.error('数据请求失败,请重新提交');

        })
        .finally(() => {
            loading.close();
        });

    },
    mounted() {
      window.addEventListener('resize', this.updateTableHeight);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateTableHeight);
    },
    methods: {
        /* eslint-disable */
        handleClick(tab, event) {
            console.log(tab);
           if (tab.label == "Alignments") {
            this.multipleSelection=[];
           }

        },
        /* eslint-enable */
        getPageHeight() {
            // 获取页面高度
            return (window.innerHeight || document.documentElement.scrollHeight || document.body.scrollHeight) / 10 * 9.2 - 120;
        },
        exportExcel() {
          // 创建一个工作簿
          const workbook = XLSX.utils.book_new();
            

            //   判断是否有选中的数据
            if (this.multipleSelection.length === 0) {
                this.$message.error('请至少选择一条数据');
                return;
            }
          
          // 将 JSON 数据转换为工作表
          const worksheet = XLSX.utils.json_to_sheet(this.multipleSelection);

          // 将工作表添加到工作簿中
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

          // 生成 Excel 文件并下载
          XLSX.writeFile(workbook, 'tableData.xlsx');
        },
        exportExcel1() {
          // 创建一个工作簿
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(this.AlignmentsTableData);

          // 将工作表添加到工作簿中
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

          // 生成 Excel 文件并下载
          XLSX.writeFile(workbook, 'tableData.xlsx');
        },
        handleSelectionChange(val){
            console.log(val);
            this.multipleSelection = val;
        },
        updateTableHeight() {
          this.pgaeHeight = this.getPageHeight();
        },
        
    }
}
</script>

<style scoped>



.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 70%;
    /* 设置内容宽度为70% */
}

.custom-tabs ::v-deep .el-tabs__item {
    padding: 40px 20px;
    font-size: 18px;
}

.tab-content {
    margin-top: 20px;
    /* 增加Tab内部头部和内容之间的距离 */
}

.sequence {
    white-space: pre-wrap;
    /* 保留空格和换行符 */
    font-family: monospace;
    /* 使用等宽字体 */
    display: block;
    /* 将 span 显示为块级元素 */
    margin: 0;
    /* 移除默认的外边距 */
    padding: 0;
    /* 移除默认的内边距 */
}
</style>
<template>
  <div class="form-container">
    <el-form class="form-inline">
      <el-form-item class="form-item">
        <template #label>
          序列输入框（字符数: {{ input1Length }}，光标位置: 行 {{ input1CursorPosition.line + 1 }}，位置 {{ input1CursorPosition.ch + 1 }}）
          <el-tooltip class="item" effect="dark" content="复制" placement="top">
              <i class="el-icon-document" @click="copySequence"></i>
          </el-tooltip>
        </template>
        <div ref="editor1" class="resizable-input"></div>
      </el-form-item>
    </el-form>

    <el-button type="primary" @click="submit">提交</el-button>
  </div>
</template>

<script>
import CodeMirror from 'codemirror'; // 引入 CodeMirror 库
import 'codemirror/lib/codemirror.css'; // 引入 CodeMirror 的 CSS 文件
import 'codemirror/mode/javascript/javascript'; // 引入 JavaScript 模式文件

export default {
  data() {
    return {
      input1: '', // 输入框1的内容
      input2: '', // 输入框2的内容
      input1Length: 0, // 输入框1的字符数
      input2Length: 0, // 输入框2的字符数
      input1CursorPosition: { line: 0, ch: 0 }, // 输入框1的光标位置
      input2CursorPosition: { line: 0, ch: 0 }, // 输入框2的光标位置
    };
  },
  mounted() {
    // 初始化 CodeMirror 编辑器1
    this.editor1 = CodeMirror(this.$refs.editor1, {
      value: this.input1, // 设置编辑器的初始值
      mode: 'javascript', // 设置编辑器的模式为 JavaScript
      lineNumbers: true, // 显示行号
      
      lineWrapping: true, //自动换行
    });

    // 初始化 CodeMirror 编辑器2
    this.editor2 = CodeMirror(this.$refs.editor2, {
      value: this.input2, // 设置编辑器的初始值
      mode: 'javascript', // 设置编辑器的模式为 JavaScript
      lineNumbers: true, // 显示行号
    });
 
    // 监听编辑器1内容的变化
    this.editor1.on('change', this.updateInput1);
    // 监听编辑器2内容的变化
    this.editor2.on('change', this.updateInput2);
    // 监听编辑器1光标活动
    this.editor1.on('cursorActivity', this.updateCursor1);
    // 监听编辑器2光标活动
    this.editor2.on('cursorActivity', this.updateCursor2);
  },
  methods: {
    // 更新输入框1的内容和字符数
    updateInput1(editor) {
      this.input1 = editor.getValue(); // 获取编辑器1的内容
      this.input1Length = this.input1.length; // 更新字符数
    },
    // 更新输入框2的内容和字符数
    updateInput2(editor) {
      this.input2 = editor.getValue(); // 获取编辑器2的内容
      this.input2Length = this.input2.length; // 更新字符数
    },
    // 更新输入框1的光标位置
    updateCursor1(editor) {
      const cursor = editor.getCursor(); // 获取光标位置
      this.input1CursorPosition = { line: cursor.line, ch: cursor.ch }; // 更新光标位置
    },
    // 更新输入框2的光标位置
    updateCursor2(editor) {
      const cursor = editor.getCursor(); // 获取光标位置
      this.input2CursorPosition = { line: cursor.line, ch: cursor.ch }; // 更新光标位置
    },
    submit() {
      // 提交表单
      console.log('提交内容：', this.input1,);


        this.$router.push({ name: "sequenceResult",params:{data:this.input1} });
     


    },
    copySequence() {
        const el = document.createElement('textarea');
        el.value = this.input1;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$message({
            message: '已复制序列',
            type: 'success'
        });
    }
    
  }
};
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 960px;
}

.form-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.form-item {
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

.form-item:last-child {
  margin-right: 0;
}

.resizable-input {
  height: 300px;
  width: 100%;
  border: 1px solid #dcdfe6;
  
}
</style>
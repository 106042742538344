<template>
  <div class="container">
    <div class="card-list">
      <el-card v-for="(card, index) in cards" :key="index" class="card">
        <div slot="header" class="clearfix">
          <span class="card-title">{{ card.name }}</span>
        </div>
        <div class="card-content">
          <div class="card-details">
            <div class="card-detail-row">
              <strong>CAS:</strong> <span class="card-text">{{ card.cas }}</span>
            </div>
            <div class="card-detail-row">
              <strong>Formula:</strong> <span class="card-text">{{ card.formula }}</span>
            </div>
            <div class="card-detail-row">
              <strong>Molecular Weight:</strong> <span  v-if="card.molecularWeight" class="card-text">{{ card.molecularWeight }}</span>
              <span v-else>暂无数据</span>
            </div>
            <div class="card-detail-row">
              <strong>SMILES:</strong>

                

              <span  v-if="card.smiles" class="card-text click-to-copy" @click="copyToClipboard(card.smiles)"
                @mouseover="showTooltip($event, card.smiles)" @mouseout="hideTooltip">
                点击复制
              </span>
              <span v-else >
                暂无数据
              </span>

            </div>
            <div class="card-detail-row">
              <strong>InChI:</strong>
              <span v-if="card.inChi" class="card-text click-to-copy" @click="copyToClipboard(card.inChi)"
                @mouseover="showTooltip($event, card.inChi)" @mouseout="hideTooltip">
                点击复制
              </span>
              <span v-else>
                暂无数据
              </span>

            </div>
            <div class="card-detail-row">
              <strong>InChIKey:</strong> <span class="card-text">{{ card.inChiKey }}</span>
            </div>
            <div class="card-detail-row">
              <strong>Source:</strong> <span class="card-text">{{ card.source }}</span>
            </div>
            <div class="card-detail-row">
              <strong>Type:</strong> <span class="card-text">{{ card.type }}</span>
            </div>
            <a :href="card.molPath" target="_blank">Molecular Structure</a>
          </div>
          <div class="card-image">
            <a :href="card.imgPath" target="_blank">
              <img :src="card.imgPath" alt="Structure Image">
            </a>
          </div>
          
        </div>
        <br>
        <div class="card-footer">
          <strong>EC:</strong>
          <span>
            <span class="clickable-ec" v-for="(value, index) in card.eclist" :key="index" @click="goToDigitalEnzymeLibrary(card.imgPath,value)">{{ value }}</span>
          </span>
        </div>
      </el-card>
      <div v-if="tooltipVisible" class="tooltip" :style="tooltipStyle">{{ tooltipText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardList',
  data() {
    return {
      cards: [

      ],
      tooltipVisible: false,
      tooltipText: '',
      tooltipStyle: {
        left: '0px',
        top: '0px'
      }
    };
  },
  created() {
    if (this.$route.params.tableData) {
      this.cards = this.$route.params.tableData;
      // 循环遍历cards，为每个card添加eclist属性，值为将ec进行切分后的字符串
      this.cards.forEach(card => {
        card.eclist = card.ec.split('|');
      });
      console.log(this.cards);

    }else{
      this.$message({
        message: '数据加载失败',
        type: 'error'
      });
      this.$router.push({ name:'数字化酶库表格页面' });
    }

  },
  methods: {
    // 复制剪贴板
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.$message({
            message: '复制成功',
            type: 'success'
          });
        }).catch(err => {
          console.error('Failed to copy text: ', err);
          this.$message({
            message: '复制失败',
            type: 'error'
          });
        });
      } else {
        // 使用旧的 execCommand 方法作为备选方案
        const input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        const result = document.execCommand('copy');
        document.body.removeChild(input);

        if (result) {
          this.$message({
            message: '复制成功',
            type: 'success'
          });
        } else {
          this.$message({
            message: '复制失败',
            type: 'error'
          });
        }
      }
    },
    // 显示提示框
    showTooltip(event, text) {
      this.tooltipText = text;
      this.tooltipVisible = true;
      this.tooltipStyle = {
        left: `${event.pageX + 10}px`, // 使用 pageX 替换 clientX
        top: `${event.pageY + 5}px`,  // 使用 pageY 替换 clientY
        display: 'block' // 确保以块状元素显示
      };
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    // 跳转三元关系
    goToDigitalEnzymeLibrary(imgPath, ec) {
      console.log(imgPath, ec);

      let href = btoa(imgPath)
      console.log(href);

      this.$router.push({ name: '数字化酶库', query: { href: href, ec: ec } });
      // this.$axios.post('/CompoundDB/TriadicRelationshipByECReaction',{
      //   substrate:imgPath,
      //   EC:ec
      // }).then(res => {
      //   console.log(res);
      //   // this.$router.push({ name: '数字化酶库三元关系', params: { tableData: res.data } });
      // }).catch(err => {
      //   console.error(err);
      //   this.$message({
      //     message: '数据加载失败',
      //     type: 'error'
      //   });
      // });


    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
}

.card-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  max-width: 800px;
  margin-top: 5vh;
}

.card {
  width: 100%;
  height: 100%;
}

.card-content {
  display: flex;
  justify-content: space-between;
}

.card-details {
  flex: 1;
  margin-right: 10px;
}
.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.card-detail-row strong {
  flex-shrink: 0;
  margin-right: 5px;
}

.card-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.click-to-copy {
  cursor: pointer;
  color: blue;
  /* text-decoration: underline; */
}
.tooltip {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  white-space: nowrap;
}

.card-image {
  flex-shrink: 0;
  width: 200px; /* 调整图片容器的宽度为200px */
}

.card-image img {
  width: 100%; /* 图片宽度自适应容器宽度 */
  height: auto; /* 高度自动，保持图片的原始比例 */
}

.card-title {
  white-space: normal; /* 允许文本换行 */
  overflow: hidden; /* 超出容器的文本会被隐藏 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  max-height: 3em; /* 设置最大高度，这里的高度应等于行高乘以行数，例如2行或3行 */
  line-height: 1.5em; /* 设置行高 */
  display: -webkit-box; /* 使文本作为盒模型显示 */
  -webkit-line-clamp: 2; /* 限制在2行内 */
  -webkit-box-orient: vertical; /* 设置盒模型的方向为垂直 */
}


.card-footer {
  padding: 10px;
  background-color: #FFFFFF;
  border-top: 1px solid #ebeef5;
  text-align: left; /* 设置文本对齐方式为左对齐 */
}
.clickable-ec {
  cursor: pointer; /* 设置鼠标悬停时的指针为手型 */
  padding: 0.2em 0.5em; /* 增加点击区域，看起来更友好 */
  margin-right: 0.5em; /* 为每个元素添加一些右边距 */
  color: black; /* 默认颜色 */
  transition: color 0.3s; /* 平滑颜色变换效果 */
}

.clickable-ec:hover {
  color: blue; /* 鼠标悬停时颜色变为蓝色 */
}
</style>

<template>
  <div>
    <div class="not-found">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <div>
        <router-link to="/">Go Home</router-link>
      </div>
      <div>
        <router-link to="https://pubchem.ncbi.nlm.nih.gov/">pubchem</router-link>
      </div>
    </div>
  </div>



  </template>
  
  <script>
  export default {
    name: 'NotFound',
    created() {
        console.log(this.$route.params);
       
    },
  };
  </script>
  
  <style scoped>
  .not-found {
    text-align: center;
    padding: 20px;
  }
  
  h1 {
    font-size: 2rem;
    color: #f00; /* You can customize the color */
  }
  
  p {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  /* You can add additional styles as needed */
  </style>
  
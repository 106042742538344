<template>
  <div class="login-container">
    <!-- 用户名密码登录 -->
    <el-form v-show="showLogin" @submit.prevent="login" class="login-form">
      <h3 class="switch-method" @click="switchLogin">切换到手机登录</h3>
      <el-input v-model="username" placeholder="请输入用户名" prefix-icon="el-icon-user" class="input-item"></el-input>
      <el-input v-model="password" placeholder="请输入密码" show-password prefix-icon="el-icon-lock" class="input-item"></el-input>
      <el-input v-model="code" placeholder="请输入验证码" prefix-icon="el-icon-message" class="input-item" clearable>
        <img :src="imageSrc" slot="append" @click="newverify()" alt="验证码" class="verify-img">
    </el-input>
      <el-button type="primary" class="login-btn" @click="login">登录</el-button>
      <div class="link-group">
        <span @click="ToRegister">没有账号？马上注册</span>
        <br>
        <br>
        <span @click="ToUpdate">点击修改密码</span>
      </div>
    </el-form>

    <!-- 电话号码登录 -->
    <el-form v-show="showphoneLogin" @submit.prevent="phonelogin" class="login-form">
      <h3 class="switch-method" @click="switchLogin">切换账号登录</h3>
      <!-- <el-alert v-if="showTishi" :closable="false" type="info">{{ tishi }}</el-alert> -->
      <el-input v-model="newTel" placeholder="请输入电话" prefix-icon="el-icon-mobile-phone" class="input-item"></el-input>
      <el-input v-model="telCode" placeholder="请输入短信验证码" class="input-with-select" clearable>
        <el-button slot="append" @click="sendCode1" :disabled="time !== 60" class="send-code-btn">{{ buttonText }}</el-button>
      </el-input>
      <el-button type="primary" class="login-btn" @click="phonelogin" >登录</el-button>
      <div class="link-group">
        <span @click="ToRegister">没有账号？马上注册</span>
        <span @click="ToUpdate">点击修改密码</span>
      </div>
    </el-form>

    <!-- 注册表单 -->
    <el-form v-show="showRegister" @submit.prevent="register" class="login-form">
      <h3>注册</h3>
      <!-- <el-alert v-if="showTishi" :closable="false" type="info">{{ tishi }}</el-alert> -->
      <el-input v-model="newUsername" placeholder="请输入用户名" prefix-icon="el-icon-user" class="input-item" clearable></el-input>
      <el-input v-model="newPassword" placeholder="请输入密码" show-password prefix-icon="el-icon-lock" class="input-item" clearable></el-input>
      <el-input v-model="newEmail" placeholder="请输入邮箱" prefix-icon="el-icon-message" class="input-item" clearable></el-input>
      <el-input v-model="newRealName" placeholder="请输入真实姓名" prefix-icon="el-icon-user" class="input-item" clearable></el-input>
      <el-input v-model="newTel" placeholder="请输入电话" prefix-icon="el-icon-mobile-phone" class="input-item" clearable></el-input>
      <el-input v-model="telCode" placeholder="请输入短信验证码" class="input-with-select" clearable>
        <el-button slot="append" @click="sendCode" :disabled="time !== 60" class="send-code-btn">{{ buttonText }}</el-button>
      </el-input>
      <el-button type="primary" class="login-btn"  @click="register">注册</el-button>
      <div class="link-group">
        <span @click="ToLogin">已有账号？马上登录</span>
        <span @click="ToUpdate">点击修改密码</span>
      </div>
    </el-form>

    <!-- 修改密码表单 -->
    <el-form v-show="showUpdate" @submit.prevent="updatePassword" class="login-form">
      <h3>修改密码</h3>
      <!-- <el-alert v-if="showTishi" :closable="false" type="info">{{ tishi }}</el-alert> -->
      <el-input v-model="newUsername" placeholder="请输入用户名" prefix-icon="el-icon-user" class="input-item"></el-input>
      <el-input v-model="newPassword" placeholder="请输入新密码" show-password prefix-icon="el-icon-lock" class="input-item"></el-input>
      <el-input v-model="newTel" placeholder="请输入电话" prefix-icon="el-icon-mobile-phone" class="input-item"></el-input>
      <el-input v-model="telCode" placeholder="请输入短信验证码" class="input-with-select">
        <el-button slot="append" @click="sendUpPasswordCode" :disabled="time !== 60" class="send-code-btn">{{ buttonText }}</el-button>
      </el-input>
      <el-button type="primary" class="login-btn" @click="uppassword" >修改密码</el-button>
      <div class="link-group">
        <span @click="ToLogin">已有账号？马上登录</span>
        <span @click="ToUpdate">点击修改密码</span>
      </div>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios';

import { EventBus } from '../../eventBus';
export default {
    data() {
        return {
            showLogin: true,
            showRegister: false,
            username:'',
            password:'',
            newUsername:'',
            newPassword:'',
            newEmail:'',
            newRealName:'',
            newTel:'',

            code: '',
            imageSrc: 'https://proteinuniverse.com.cn/topo/login/imageVerify',
            telCode: '',
            time: 60,
            timer: null,
            showphoneLogin: false,
            showUpdate: false
        }
    },
    created() {

    },
    computed: {
        buttonText() {
            return this.time === 60 ? '发送验证码' : `${this.time}秒后重发`;
        },
    },
    methods: {
        switchLogin() {

            // 切换登录方式
            console.log(this.showLogin);
            if (this.showLogin) {
                this.showLogin = false;
                this.showRegister = false;
                this.showphoneLogin = true
            } else {
                this.showLogin = true;
                this.showRegister = false;
                this.showphoneLogin = false;
            }

        },
        sendCode() {
            if (this.time < 60) return;
            // 这里添加发送验证码的逻辑
            if(!this.isPhoneNumberValid()){
                this.$message({
                    message: '手机号格式不正确',
                    type: 'warning'
                });
                return;
            }

            axios.post('/topo/singUp/phoneVerify', { phone: this.newTel }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: '发送成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: '发送失败',
                        type: 'error'
                    });
                }
            })
            this.time = 59;
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    clearInterval(this.timer);
                    this.time = 60;
                }
            }, 1000);
        },
        sendCode1(){
            // 登录手机验证码
            if(!this.isPhoneNumberValid()){
                this.$message({
                    message: '手机号格式不正确',
                    type: 'warning'
                });
                return;
            }
            if (this.time < 60) return;
            // 这里添加发送验证码的逻辑
            axios.post('/topo/login/phoneVerify', { phone: this.newTel }).then(res => {
                console.log("验证码返回", res);
                if (res.data.status == 200) {
                    this.$message({
                        message: '发送成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            })
            this.time = 59;
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    clearInterval(this.timer);
                    this.time = 60;
                }
            }, 1000);
        },
        sendUpPasswordCode(){
            if (this.time < 60) return;
            // 这里添加发送验证码的逻辑
            if(!this.isPhoneNumberValid()){
                this.$message({
                    message: '手机号格式不正确',
                    type: 'warning'
                });
                return;
            }

            axios.post('/topo/updateUser/sendVerificationCode', { phone: this.newTel }).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: '发送成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: '发送失败',
                        type: 'error'
                    });
                }
            })
            this.time = 59;
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    clearInterval(this.timer);
                    this.time = 60;
                }
            }, 1000);
        },
        beforeDestroy() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        ToRegister() {
            // 切换到注册页面
            this.showLogin = false;
            this.showRegister = true;
            this.showphoneLogin = false;
            let timestamp = new Date().getTime();
            this.showUpdate = false;
            console.log(timestamp);

        },
        ToUpdate() {
            // 切换到修改密码页面
            this.showLogin = false;
            this.showphoneLogin = false;
            this.showRegister = false;
            this.showUpdate = true;
            let timestamp = new Date().getTime();
            console.log(timestamp);
        },
        ToLogin() {
            // 切换到登录页面
            this.showLogin = true;
            this.showRegister = false;
            this.showUpdate = false;

        },
        login() {
            // 登录
            if (this.username == '' || this.password == '') {
                this.$message({
                    message: '用户名或密码不能为空',
                    type: 'warning'
                });
                return;
            }
            axios.post('/topo/login', {
                username: this.username,
                password: this.password,
                code: this.code
            }).then(res => {
                console.log(res);
                if (res.status == 200) {
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    });
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('login', true);
                    EventBus.$emit('login-success');
                    this.$router.push('/');
                } else {
                    this.$message({
                        message:  res.data.msg,
                        type: 'error'
                    });
                }
            }).catch(err => {
                this.$message({
                    message: err.response.data,
                    type: 'error'
                });
            })
        },
        phonelogin() {
            // 手机号登录
            if (this.newTel == '' || this.telCode == '') {

                this.$message({
                    message: '请全部填写',
                    type: 'warning'
                });
                return;
            }
           console.log("手机号校验",this.isPhoneNumberValid());
           axios.post('/topo/phoneLogin').then(res => {
                console.log(res);
                if (res.status == 200) {
                    this.$router.push('/');
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    });
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('login', true);
                } else {
                    // this.showTishi = true;
                    // this.tishi = res.data.msg;
                    this.$message({
                        message: '登录失败，请联系管理员',
                        type: 'error'
                    });
                }
            }).catch(err => {
                this.$message({
                    message: err.response.data,
                    type: 'error'
                });
            })
        },
        uppassword(){
            // 修改密码
            if (this.newUsername == '' || this.newPassword == '' || this.newTel == '' || this.telCode == '') {

                this.$message({
                    message: '请全部填写',
                    type: 'warning'
                });
                return;
            }
            if (!this.validatePassword(this.newPassword)) {
                this.$message({
                    message:  '密码包含大小写字母、数字、特殊字符，长度8-16位',
                    type: 'warning'
                });
                return;
            }
            if(!this.isPhoneNumberValid()){
                this.$message({
                    message: '手机号格式不正确',
                    type: 'warning'
                });
                return;
            }
            axios.post('/topo/updateUser/updatePassword', {
                password: this.newPassword,
                phone: this.newTel,
                checkCode: this.telCode
            }).then(res => {
                console.log(res);
                if (res.status == 200) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.ToLogin();
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            }).catch(err => {
                this.$message({
                    message: err.response.data,
                    type: 'error'
                });
            })
        },
        register() {
            // 注册
            if (this.newUsername == '' || this.newPassword == '' || this.newEmail == '' || this.newRealName == '' || this.newTel == '' || this.telCode == '') {

                this.$message({
                    message: '请全部填写',
                    type: 'warning'
                });
                return;
            }
            if (!this.validatePassword(this.newPassword)) {
                this.$message({
                    message:  '密码包含大小写字母、数字、特殊字符，长度8-16位',
                    type: 'warning'
                });
                return;
            }
            if(!this.isPhoneNumberValid()){
                this.$message({
                    message: '手机号格式不正确',
                    type: 'warning'
                });
                return;
            }

            this.$axios.post('/topo/Sing-up', {
                // String username, String password, String email, String name, String tel, String checkcode
                username: this.newUsername,
                password: this.newPassword,
                email: this.newEmail,
                name: this.newRealName,
                phone: this.newTel,
                checkcode: this.telCode

            }, {
                headers: {
                    Authorization: this.getCookie('JSESSIONID')

                },
                withCredentials: true
            }).then(res => {
                console.log(res);
                if (res.data.status == 200) {

                    this.$message({
                        message: '注册成功',
                        type: 'success'
                    });
                    this.ToLogin();
                } else {
                    this.$message({
                        message:res.data.msg,
                        type: 'error'
                    });
                }
            }).catch(err => {
                this.$message({
                    message: err.response.data,
                    type: 'error'
                });
            })
        },
        validatePassword(password) {
            const lengthRegex = /^.{8,16}$/; // 检查长度 8-16 位
            const uppercaseRegex = /[A-Z]/;  // 至少一个大写字母
            const lowercaseRegex = /[a-z]/;  // 至少一个小写字母
            const numberRegex = /[0-9]/;     // 至少一个数字
            const specialCharRegex = /[^A-Za-z0-9]/; // 至少一个特殊字符

            // 检查长度
            if (!lengthRegex.test(password)) {
                return false;
            }

            // 检查其他条件
            let count = 0;
            if (uppercaseRegex.test(password)) count++;
            if (lowercaseRegex.test(password)) count++;
            if (numberRegex.test(password)) count++;
            if (specialCharRegex.test(password)) count++;

            // 检查是否满足至少三种条件
            return count >= 3;
        },
        isPhoneNumberValid() {
            // 手机号验证
            const regex = /^1[3-9]\d{9}$/;
            return regex.test(this.newTel);
        },
        newverify() {
            // 刷新验证码
            let timestamp = new Date().getTime();
            this.imageSrc = this.$axios.defaults.baseURL+'/topo/login/imageVerify?' + timestamp;
        },
        getCookie(name) {
            let cookieArray = document.cookie.split(';'); // 将所有cookie分割成数组
            for (let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i];
                let [key, value] = cookie.trim().split('='); // 分割每个cookie的键和值
                if (key === name) {
                    return value; // 如果找到匹配的键，则返回对应的值
                }
            }
            return ''; // 如果没有找到，返回空字符串
        }
    }
}
</script>

<style scoped>

.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
}


.login-form {
  margin: 20px 0;
}



.login-btn {
  width: 100%;
  margin-top: 10px;
}

.switch-method {
  cursor: pointer;
  color: #409EFF;
  margin-bottom: 20px;
}

.link-group {
  text-align: center;
  margin-top: 15px;
}

.link-group span {
  cursor: pointer;
  color: #409EFF;
  margin: 0 5px;
}

.send-code-btn {
  cursor: pointer;
}
.input-item .verify-img {
  height: 100%; /* 让图片高度与输入框相同 */
  width: auto;  /* 宽度自动调整以保持图片的宽高比 */
  cursor: pointer; /* 可选，鼠标悬停时显示手形光标 */
  
}
.input-item {
  margin-bottom: 20px; /* 增加输入框之间的间隔 */
}
.verify-img {
  height: 100%; /* 确保图片高度与输入框相同 */
  width: auto;  /* 宽度自动调整以保持图片的宽高比 */
  display: block; /* 避免默认的行内元素间隙 */
  border: none;  /* 移除可能存在的边框 */
  padding: 0;    /* 移除内边距 */
  object-fit: cover; /* 覆盖容器但保持宽高比，可能会裁切图片 */
}
.switch-method {
  text-align: center; /* 文本居中 */
  width: 100%; /* 确保元素占满整个容器的宽度 */
}
</style>

<template>
    <div class="input-container">
        <el-input   class="input-with-select" type="text" v-model="searchQuery" @keyup.enter="performSearch" :placeholder="inputText" >
        
        <el-button slot="append" @click="performSearch">搜索</el-button>

        </el-input>
    </div>
</template>
    
<script>

import routes from "@/router/index.js";
export default {
    name: 'SearchBox',
    data() {
        return {
            searchQuery: '', // 用户输入的搜索查询
            index: "", // 标识搜索对应的接口,
            result: [],
            map: {
                '/DigitalEnzymeLibrary': "/EnzymeTernaryRelationship",
                // '/DigitalEnzymeLibrary': "/page",
                '/CompoundLibrary': "/CompoundLibrary",
                // '/CompoundLibrary': "/page"
                // '/EnzymeLibraryInformationVerification': "/EnzymeTernaryRelationship",
            },
            inputText: '',
        };
    },
    created() {
        // 当组件被创建时，从路由查询参数中获取 keyword 并设置 searchQuery
        if (this.$route.query.keyword) {
            this.index = this.$route.query.keyword;
        }
        if (this.index === '化合物库') {
            this.inputText = '请输入化合物的CAS号或名称或smiles';
        } 

    },

    watch: {
        // 监视路由对象 ($route) 的变化
        '$route': {
            immediate: true, // 立即以当前的路由作为初始调用
            handler(to) {
                // 当路由变化时调用此函数
                if (to.query.keyword) {
                    this.index = to.query.keyword;
                    if (this.index === '化合物库') {
                        this.inputText = '请输入化合物的CAS号';
                    } else if (this.index === '数字化酶库') {
                        this.inputText = '请输入酶的名称';
                    }
                }

            }
        }
    },
    methods: {


        getData(query, mothod, params, routeName) {

            console.log("向后端传递数据" + this.searchQuery);
            // { CAS: this.searchQuery }
            if (mothod === 'post') {
                this.$axios.post('' + '/CompoundDB/CompoundSearch', params)
                    .then((response) => {
                        this.result = response.data.data; // 将数据存储在 compoundData 中
                        if (this.result.length === 0) {
                            this.$message({
                                message: '没有数据',
                                type: 'error'
                            });
                            // console.log("没有数据");
                            // this.$router.push({
                            //     name: 'NotFound',
                            //     params: { keyword: this.searchQuery, index: this.index }
                            // });
                        } else {
                            this.$router.push({
                                name: routeName,
                                params: { keyword: this.searchQuery, index: this.index, result: this.result }
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(query + "请求失败");
                        console.log(error);
                        this.loading = false; // 请求失败，关闭加载动画
                    });
            }
        },

        performSearch() {
            // 当搜索按钮被点击时执行
            console.log(this.index);

            if (this.index === '化合物库') {
                // 将this.searchQuery作为路由参数传递给目标路由
                console.log(routes.options.routes);
                if (this.searchQuery === '') {
                    this.$message.error('请输入化合物的CAS号或名称或smiles');
                    return;
                }
                let routeList = routes.options.routes
                let foundRoute = routeList.find(element => element.name === this.index);
                this.getData(this.map[foundRoute.path], 'post', { value: this.searchQuery }, this.index);
            } 



        }
    }
};
</script>
  
  
<style scoped>


.input-container {
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    height: 100%;
    /* 或者你可以设置为特定的高度 */
    margin-top: 5%;
}


.input-with-select {
    width: 40%;
    /* input的宽度为容器的40% */
}
</style>
  
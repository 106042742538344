<template>
    <div>
        <br>
        <div class="input-container">
            <el-input :placeholder="tishi" v-model="filterQuery" class="input-with-select">
                <el-select v-model="filterQueryHeader" filterable slot="prepend" style="width: 150px;"
                    placeholder="请选择">
                    <el-option v-for="(label, prop) in tempForm" :key="prop" :label="label" :value="prop"></el-option>
                </el-select>
                <el-button slot="append" @click="addFilter">添加搜索条件</el-button>

                <el-button slot="append" icon="el-icon-search" @click="filterData"></el-button>
            </el-input>
        </div>

        <div style="height: 40px;" class="input-container">
            <el-tag v-for="(filter, index) in filters" :key="index" closable @close="removeFilter(index)"
                style="margin: 5px;">
                '{{ filter.key }}' 包含 '{{ filter.value }}'
            </el-tag>
            <el-button type="danger" @click="clearFilter" v-show="filters.length">清除所有筛选条件</el-button>
        </div>
        <div class="container" :style="{ height: pageHeight }">
            <el-table :data="tableData" style="width: 100%;" :max-height="pageHeight" border>
                <el-table-column prop="ec" label="EC号(点击查看其他数据)" width="180">
                    <template slot-scope="scope">
                        <div @click="toDigitaLibrary(scope.row)">
                            <el-button type="text">{{scope.row.ec }}</el-button>
                        </div>


                    </template>
                </el-table-column>
                <el-table-column prop="enzyme" label="酶名称" width="180">
                    <template slot-scope="scope">
                        <div @click="toSequenceBox(scope.row)">{{ scope.row.enzyme }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="反应" width="800">
                    <template slot-scope="scope">
                        <div class="reaction-format">
                            <!-- 底物1 -->
                            <div class="item" v-if="scope.row.sub1Img || scope.row.sub2Img">
                                <img v-if="scope.row.sub1Img" :src="scope.row.sub1Img" alt="Sub1 Image"
                                    class="reaction-img" @click="openImg(scope.row.sub1Img)" style="cursor: pointer;" />
                                <span v-if="scope.row.sub1name" @click="jumpToCompound(scope.row.sub1name)"
                                    class="hover-pointer">{{ scope.row.sub1name }}</span>
                            </div>
                            <!-- 底物1和底物2的加号 -->
                            <span v-if="(scope.row.sub1Img && scope.row.sub2Img) || (scope.row.sub1name && scope.row.sub2name)"> + </span>
                            <!-- 底物2 -->
                            <div class="item" v-if="scope.row.sub2Img">
                                <img v-if="scope.row.sub2Img" :src="scope.row.sub2Img" alt="Sub2 Image"
                                    class="reaction-img" @click="openImg(scope.row.sub2Img)" style="cursor: pointer;" />
                                <span v-if="scope.row.sub2name" @click="jumpToCompound(scope.row.sub2name)"
                                    class="hover-pointer">{{ scope.row.sub2name }}</span>
                            </div>
                            <!-- 反应箭头 -->
                            <span class="arrow"> => </span>
                            <!-- 产物1 -->
                            <div class="item" v-if="scope.row.pro1Img || scope.row.pro1name" style="cursor: pointer;">
                                <img v-if="scope.row.pro1Img" :src="scope.row.pro1Img" alt="Pro1 Image"
                                    class="reaction-img" @click="openImg(scope.row.pro1Img)" />
                                <span v-if="scope.row.pro1name" @click="jumpToCompound(scope.row.pro1name)"
                                    class="hover-pointer">{{ scope.row.pro1name }}</span>
                            </div>
                            <!-- 产物1和产物2的加号 -->
                            <span v-if="(scope.row.pro1Img && scope.row.pro2Img) || (scope.row.pro1name && scope.row.pro2name)"> + </span>
                            <!-- 产物2 -->
                            <div class="item" v-if="scope.row.pro2Img">
                                <img v-if="scope.row.pro2Img" :src="scope.row.pro2Img" alt="Pro2 Image"
                                    class="reaction-img" style="cursor: pointer;" @click="openImg(scope.row.pro2Img)" />
                                <span v-if="scope.row.pro2name" @click="jumpToCompound(scope.row.pro2name)"
                                    class="hover-pointer">{{ scope.row.pro2name }}</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="organism" label="生物体" width="180"></el-table-column>
                <el-table-column prop="reversibility" label="是否可逆" width="180"></el-table-column>
                <el-table-column prop="km" label="酶对底物的活性(KM)"></el-table-column>
                <el-table-column prop="subsource" label="底物数据源" width="180" class-name="word-break"></el-table-column>
                <el-table-column prop="subComment" label="底物注释" width="180" class-name="word-break"></el-table-column>
                <el-table-column prop="prosource" label="产物数据源" width="180" class-name="word-break"></el-table-column>
                <el-table-column prop="proComment" label="产物注释" width="180" class-name="word-break"></el-table-column>
                <el-table-column prop="source" label="来源" width="180"></el-table-column>
            </el-table>
        </div>



    </div>
</template>


<script>


export default {
    data() {
        return {
            tishi: '请输入内容',
            select: '',
            filters: [],
            filterQuery: '',//过滤条件的值
            filterQueryHeader: '',
            tempForm: {},
            tableData: [],
            tempTableData: [],
            maxTotal: 0,
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            pageHeight: 0, // 初始化页面高度
            input: '',
            keyword: '',
            currentPage3: 1,
            tableKeys: '',
            form1: {
                "ec": "ec",
                "酶名称": "enzyme",
                "底物1": "sub1name",
                "底物1结构": "sub1",
                "底物2": "sub2name",
                "底物2结构": "sub2",
                "产物1": "pro1name",
                "产物结构": "pro1",
                "产物2": "pro2name",
                "产物2结构": "pro2",
                "生物体": "organism",
                "是否可逆": "reversibility",
                "酶对底物的活性(KM)": "km",
                "底物数据源": "subsource",
                "底物注释": "subComment",
                "产物数据源": "prosource",
                "产物注释": "proComment",
                "来源": "source"
            },
            onlyFilter: [],
            s: true,
            unknownValue: '',
            systemValue: '',
            synonymValue: '',
        }
    },
    created() {

        window.addEventListener('beforeunload', this.saveState);
        setTimeout(() => {
            this.loading = false;
        }, 500);

        let dataList = [];

        this.s = this.$route.query.status == 0 ? true : false;

        let localResult = localStorage.getItem("Fuzzy")

        if (localResult && this.$route.query.status == 1) {
            this.handleData(JSON.parse(localResult)['data']);
        }
        else if (localResult && this.$route.query.status == 0) {
            switch ((this.$route.query.datastus).toString()) {
                case '0':
                    this.unknownValue = window.atob(this.$route.query.value);
                    break;
                case '1':
                    this.systemValue = window.atob(this.$route.query.value);
                    break
                case '2':
                    this.synonymValue = window.atob(this.$route.query.value);
                    break;
                case '3':
                    this.systemValue = window.atob(this.$route.query.value).split("|")[0]
                    this.synonymValue = window.atob(this.$route.query.value).split("|")[1]
                    break;

            }
            let dictionary = JSON.parse(localResult)['data'];
            for (let key in dictionary) {
                // console.log("Key:", key, "Value:", dictionary[key]);
                if (key == 'systemData') {
                    if (dictionary[key] != undefined) {
                        for (let i = 0; i < dictionary[key].length; i++) {
                            dictionary[key][i].status = '系统数据';
                            dataList.push(dictionary[key][i]);
                        }
                    }
                }
                if (key == 'synonymData') {
                    if (dictionary[key] != undefined) {
                        for (let i = 0; i < dictionary[key].length; i++) {
                            dictionary[key][i].status = '别名数据';
                            dataList.push(dictionary[key][i]);

                        }
                    }
                }
                if (key == 'unknownData') {
                    if (dictionary[key] != undefined) {
                        for (let i = 0; i < dictionary[key].length; i++) {
                            dictionary[key][i].status = '未知数据';
                            dataList.push(dictionary[key][i]);

                        }

                    }
                }
            }

            this.handleData(dataList);

        }
        else if (this.$route.params.status == 1) {
            this.handleData(this.$route.params.result);

        } else if (this.$route.params.status == 0) {
            // 使用for循环遍历this.$route.params.result，根据键将对应的每条数据增加一个字段 status
            let dictionary = this.$route.params.result;
            for (let key in dictionary) {
                // console.log("Key:", key, "Value:", dictionary[key]);
                if (key == 'systemData') {
                    if (dictionary[key] != undefined) {
                        for (let i = 0; i < dictionary[key].length; i++) {
                            dictionary[key][i].status = '系统数据';
                            dataList.push(dictionary[key][i]);
                        }
                    }
                }
                if (key == 'synonymData') {
                    if (dictionary[key] != undefined) {
                        for (let i = 0; i < dictionary[key].length; i++) {
                            dictionary[key][i].status = '别名数据';
                            dataList.push(dictionary[key][i]);

                        }
                    }
                }
                if (key == 'unknownData') {
                    if (dictionary[key] != undefined) {
                        for (let i = 0; i < dictionary[key].length; i++) {
                            dictionary[key][i].status = '未知数据';
                            dataList.push(dictionary[key][i]);

                        }

                    }
                }
            }

            this.handleData(dataList);

        }
        else {
            this.$router.push({ name: '数字化酶库表格页面' });
        }


        this.selectInit();
        this.pageHeight = this.getPageHeight();
        
    },
    methods: {
        selectInit() {
            // 初始化下拉框的数据

            // 将form1的key和value互换

            function invertObject(obj, excludeKeys = []) {
                return Object.keys(obj).reduce((acc, key) => {
                    // 检查当前键是否在排除列表中
                    if (excludeKeys.includes(key)) {
                        acc[obj[key]] = key;
                    }

                    // 如果 excludeKeys的列表中含有obj没有的键，则添加到acc
                    return acc;
                }, {});
            }
            let old_form = {}
            old_form = invertObject(this.form1, ["ec", "酶名称", "底物名称", "产物名称", "生物体", "是否可逆", "酶对底物的活性(KM)", "底物数据源", "底物注释", "产物数据源", "产物注释", "来源"]);

            this.tempForm["ec"] = "ec";
            this.tempForm["enzyme"] = "酶名称";
            this.tempForm["sub"] = "底物名称";
            this.tempForm["pro"] = "产物名称";
            // 将old_form的key和value添加到tempForm中
            for (let key in old_form) {
                this.tempForm[key] = old_form[key];
            }


        },
        addFilter() {
            if (this.filterQuery && this.filterQueryHeader) {
                // 获取当前键名
                const currentKey = this.tempForm[this.filterQueryHeader];

                // 查找数组中是否存在该键
                const existingFilterIndex = this.filters.findIndex(filter => filter.key === currentKey);

                // 去除掉this.filterQuery前面和后面的空格或者换行符
                this.filterQuery = this.filterQuery.replace(/(^\s*)|(\s*$)/g, "");
                if (existingFilterIndex !== -1 && this.onlyFilter.includes(currentKey)) {
                    // 如果找到了相应的键，并且键名为 'sub'，则更新其值
                    this.filters[existingFilterIndex].value = this.filterQuery;
                } else {
                    // 如果没有找到或键名不是 'sub'，则添加新的过滤器对象
                    this.filters.push({
                        key: currentKey,
                        value: this.filterQuery,
                    });
                }

                // 清空输入框
                this.filterQuery = '';
            }
        },
        removeFilter(index) {
            this.filters.splice(index, 1);
            if (this.filters.length == 0) {
                this.tableData = this.tempTableData;
            }
        },
        filterData() {


            const filterMap = {};
            this.filters.forEach(filter => {
                let keys = []; // 初始化空数组来存储可能的多个键

                // 特殊处理底物名称和产物名称
                if (filter.key === '底物名称') {
                    keys = ['sub1name', 'sub2name'];
                } else if (filter.key === '产物名称') {
                    keys = ['pro1name', 'pro2name'];
                } else {
                    // 对于其它情况，只有一个键
                    keys = [this.form1[filter.key]];
                }

                // 将筛选值添加到对应的键中
                keys.forEach(key => {
                    if (!filterMap[key]) {
                        filterMap[key] = [];
                    }
                    filterMap[key].push(filter.value.toLowerCase());
                });
            });

            // console.log('筛选映射', filterMap);
            this.tableData = [];

            // 使用filterMap来筛选数据
            this.tempTableData.filter(row => {
                return Object.keys(filterMap).every(key => {
                    // console.log('当前键', key);
                    return filterMap[key].some(filterValue => {
                        // console.log('筛选值', filterValue, '目标值', row[key]);
                        // console.log('是否包含', String(row[key]).toLowerCase().includes(filterValue));
                        if (String(row[key]).toLowerCase().includes(filterValue)) {
                            // console.log(row);
                            this.tableData.push(row);
                        }
                    });
                });
            });

        },


        clearFilter() {
            // 清除筛选条件的逻辑
            this.filters = [];
            this.tableData = this.tempTableData;
        },
        getKeyByValue(obj, value) {
            return Object.keys(obj).find(key => obj[key] === value);
        },
        saveState() {
            // 保存状态,避免丢失搜索的关键词
            if (this.keyword != '' && this.keyword != undefined) {
                localStorage.setItem('digitalEnzymeLibraryQuery', this.keyword);
            }

        },

        getPageHeight() {
            // 获取页面高度
            return (window.innerHeight || document.documentElement.scrollHeight || document.body.scrollHeight) / 10 * 9.2 - 120;
        },
/* eslint-disable */
        handleData(source) {
            for (const item of source) {
                let data = {
                    ec: item.ec,
                    enzyme: item.enzyme,
                    organism: item.organism,
                    reversibility: item.reversibility,
                    source: item.source,
                    km: item.km,
                    status: item.status
                };
                let shub = item.substrateJS;
                
                let regex = /(?<!(\s*[{:,]\s*))(\")(?!(\s*[}:,]\s*))/g;
                shub = shub.replace(regex, "'");

                let da = JSON.parse(shub);
                for (let i = 0; i < da.length; i++) {
                    if (i == 0) {
                        data.sub1Img = da[i].imgPath;
                        data.sub1name = da[i].name;
                        data.subsource = da[i].subsource;
                        data.subComment = da[i].subComment;
                    }
                    if (i == 1) {
                        data.sub2Img = da[i].imgPath;
                        data.sub2name = da[i].name;
                    }
                }
                let pro = item.productJS;
                pro = pro.replace(regex, "'");
                let da1 = JSON.parse(pro);
                for (let i = 0; i < da1.length; i++) {
                    if (i == 0) {
                        data.pro1Img = da1[i].imgPath;
                        data.pro1name = da1[i].name;
                        data.prosource = da1[i].prosource;
                        data.proComment = da1[i].proComment;
                    }
                    if (i == 1) {
                        data.pro2Img = da1[i].imgPath;
                        data.pro2name = da1[i].name;
                    }

                }
                this.tempTableData.push(data);
                this.tableData = this.tempTableData;
            }


        },
        jumpToCompound(name) {
            // 跳转到化合物库

            this.$axios.post('/CompoundDB/CompoundSearch', { value: name }).then((res) => {
                if (res.data.status != '200') {
                    this.$message.error(res.data.msg);
                    return;
                } else if (res.data.data.length == 0) {
                    this.$message.error('没有数据');
                    return;
                }
                let data = res.data.data;
                let Data = {
                    name: name,
                    data: data
                }


                localStorage.setItem("CompoundData", JSON.stringify(Data));
                let routeURL = this.$router.resolve({ name: '化合物库', query: { keyword: name } }).href;
                window.open(routeURL, '_blank');
            })

        },
        toDigitaLibrary(row) {
            // 跳转到数字酶库
            // console.log(row.status);
            let value = '';
            switch (row.status) {
                case "别名数据":
                    value = this.synonymValue
                    break;

                case "未知数据":
                    value = this.unknownValue
                    break;
                case "系统数据":
                    value = this.systemValue
                    break;
            }
            let enctyptValue = window.btoa(value);

            this.$router.push({
                name: '数字化酶库',
                query: {
                    ec: row.ec,
                    value: enctyptValue,
                }
            });

        }


    },
    computed: {
        // 使用计算属性动态获取页面高度
        computedPageHeight() {
            return this.getPageHeight();
        }
    },
    mounted() {
        // 组件挂载时计算页面高度
        this.pageHeight = this.getPageHeight();
        // 可以添加事件监听器来实时更新页面高度
        window.addEventListener('resize', () => {
            this.pageHeight = this.getPageHeight();
        });
    },
    beforeDestroy() {
        // 组件销毁前移除事件监听器
        window.removeEventListener('beforeunload', this.saveState);
        this.$store.commit('setDigitalEnzymeLibraryQuery', this.keyword);
        window.removeEventListener('resize', this.getPageHeight);
    },


}
</script>

<style scoped>
.container {
    display: flex;
    height: auto;
    /* 可以根据需要调整间距 */

}

.clickable {
    text-decoration: underline;
    cursor: pointer;
}

.tree-structure {
    /* 树形结构的宽度和样式 */
    width: 200px;
    max-width: 100%;
    padding: 10px;
}


.custom-input-width {
    width: 3px;
}

.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* 距离下方 5px */
    margin-bottom: 10px;
    margin-top: 10px;

}

.reaction-img {
    width: 120px;
    /* 或者任何适合的尺寸 */
    height: 120px;
    /* 保持图片的宽高比例 */
    margin-bottom: 5px;
    /* 在图片和文字之间添加一些间隔 */
}

.input-with-select {
    width: 40%;
}

.el-table .cell img {
    max-width: 100%;
}

/* 控制底物的鼠标悬停样式 */

.hover-pointer {
    cursor: pointer;
    color: inherit;
    /* 默认颜色 */
}

.hover-pointer:hover {
    color: blue;
    /* 鼠标悬停时变为蓝色 */
}

.reaction-format {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.reaction-img {
    width: 120px;
    /* 或者任何适合的尺寸 */
    height: 120px;
    /* 保持图片的宽高比例 */
    margin-bottom: 5px;
    /* 在图片和文字之间添加一些间隔 */
}


.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    /* 确保项与项之间有间隔 */
}

.arrow {
    margin: 0 5px;
}
</style>
<template>
    <div>
        <br>

        <div class="div-content" style="display: flex; justify-content: center; align-items: center;">
            <el-button type="success" @click="cle" style="margin-right: 10px;">默认</el-button>
            <el-button type="success" @click="review" style="margin-right: 10px;">是否重点复核</el-button>

            <!-- <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="Timefiltering"
                style="margin-right: 10px;">
            </el-date-picker> -->
            <el-button type="success" @click="preliminary">是否已初步复核</el-button>
        </div>
        <br>
        <div class="input-container">
            <el-input :placeholder="tishi" v-model="filterQuery" class="input-with-select">
                <el-select v-model="filterQueryHeader" filterable slot="prepend" style="width: 150px;"
                    placeholder="请选择">
                    <el-option v-for="(label, prop) in tempForm" :key="prop" :label="label" :value="prop"></el-option>
                </el-select>
                <el-button slot="append" @click="addFilter">添加搜索条件</el-button>

                <el-button slot="append" icon="el-icon-search" @click="filterData"></el-button>
            </el-input>
        </div>

        <div style="height: 40px;" class="input-container">
            <el-tag v-for="(filter, index) in filters" :key="index" closable @close="removeFilter(index)"
                style="margin: 5px;">
                {{ filter.key }}={{ filter.value }}
            </el-tag>
            <el-button type="danger" @click="clearFilter" v-show="filters.length">清除所有筛选条件</el-button>
        </div>
        <br>
        <div :style="{ height: pageHeight }">
            <el-table :data="tableData" style="width: 100% ; " :max-height="pageHeight" border>
                <el-table-column prop="abstracts" label="摘要" width="180">
                    <template slot-scope="scope">
                        <el-popover placement="bottom" title="摘要" width="1500" height="1000" trigger="click"
                            :content="scope.row.abstracts"
                            :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                            :open-delay="300">
                            <el-button type="text" slot="reference" class="text-ellipsis">{{ scope.row.abstracts
                                }}</el-button>
                        </el-popover>
                    </template>

                </el-table-column>
                <el-table-column prop="aminoAcidSeq" label="氨基酸序列" width="180">

                    <template slot-scope="scope">

                        <el-popover placement="bottom" title="氨基酸序列" width="1500" height="1000" trigger="click"
                            :content="scope.row.aminoAcidSeq"
                            :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                            :open-delay="300">
                            <el-button type="text" slot="reference" class="text-ellipsis">{{ scope.row.aminoAcidSeq
                                }}</el-button>
                        </el-popover>

                    </template>

                </el-table-column>
                <el-table-column prop="applicationDate" label="申请日期" width="180">
                </el-table-column>

                <el-table-column prop="applicationNumber" label="申请号" width="180">
                </el-table-column>
                <el-table-column prop="basePairSeq" label="碱基对序列" width="180">
                    <template slot-scope="scope">
                        <el-popover placement="bottom" title="碱基对序列" width="1500" height="1000" trigger="click"
                            :content="scope.row.basePairSeq"
                            :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                            :open-delay="300">
                            <el-button type="text" slot="reference" class="text-ellipsis">{{ scope.row.basePairSeq
                                }}</el-button>
                        </el-popover>
                    </template>
                </el-table-column>

                <el-table-column prop="ec" label="EC号" width="180">
                    <template slot-scope="scope">
                        <el-popover placement="bottom" title="EC号" width="1500" height="1000" trigger="click"
                            :content="scope.row.ec"
                            :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                            :open-delay="300">
                            <el-button type="text" slot="reference" class="text-ellipsis">{{ scope.row.ec }}</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="enzyme" label="酶" width="180">
                </el-table-column>
                <el-table-column prop="generalFormula" label="通式">
                </el-table-column>
                <el-table-column prop="pagesNumber" label="页数" width="180" class-name="word-break">
                </el-table-column>
                <el-table-column prop="title" label="专利名称" width="180" class-name="word-break">
                </el-table-column>
                <el-table-column prop="product" label="产物" width="180" class-name="word-break">
                </el-table-column>
                <el-table-column prop="reactionDescription" label="反应条件" width="180" class-name="word-break">
                    <template slot-scope="scope">

                        <el-popover placement="bottom" title="反应条件" width="1500" height="1000" trigger="click"
                            :content="scope.row.reactionDescription"
                            :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                            :open-delay="300">
                            <el-button type="text" slot="reference" class="text-ellipsis">{{
                scope.row.reactionDescription }}</el-button>
                        </el-popover>

                    </template>
                </el-table-column>
                <el-table-column prop="reactionPagesNumber" label="反应页数" width="180">
                </el-table-column>
                <el-table-column prop="seqLabel" label="序列标签" width="180">
                </el-table-column>
                <el-table-column prop="substrate" label="底物" width="180">
                </el-table-column>
                <el-table-column prop="substrateLabel" label="底物标签" width="180">
                </el-table-column>
                <el-table-column prop="verification" label="信息是否核对" width="180">
                </el-table-column>
                <el-table-column prop="pdfName" label="PDF文件名" width="180">
                    <template slot-scope="scope">
                        <a :href="scope.row.pdfName" target="_blank">点击查看pdf</a>
                    </template>
                </el-table-column>


                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button @click="edit(scope.row)" type="text" size="small"
                            style="margin-right: 10px;">编辑</el-button>
                        <el-button type="text" @click="update(scope.row)" size="small" style="margin-right: 10px;">
                            初步复核
                        </el-button>
                        <el-button type="text" @click="PointUpdate(scope.row)" size="small" style="margin-right: 10px;">
                            重点复核
                        </el-button>
                        <el-dialog title="编辑" :visible.sync="ArrVisible[scope.row.id]" :modal-append-to-body="false" :modal="false"
                            :lock-scroll="true" width="80%" :style="{ maxHeight: '90vh', overflow: 'auto' }">
                            <el-form ref="form" :model="form" label-width="113px" :inline="true">
                                <el-form-item v-for="(item, index) in Object.keys(form1)" :key="index" :label="item">
                                    <template v-if="isTextarea(item)">
                                        <el-input v-model="form[form1[item]]" style="width: 200px;" :disabled="true">
                                        </el-input>
                                    </template>
                                    <template v-else>
                                        <el-input v-model="form[form1[item]]" style="width: 200px;">
                                        </el-input>
                                    </template>
                                </el-form-item>
                            </el-form>
                            <div slot="footer" class="dialog-footer">
                                <el-button @click="cancle(scope.row) ">取 消</el-button>

                                <el-button type="primary" @click="sbummit()">确 定</el-button>
                            </div>
                        </el-dialog>
                    </template>
                </el-table-column>
            </el-table>




        </div>

    </div>
</template>


<script>
import axios from 'axios';
export default {
    data() {
        return {
            tishi: '请输入内容',
            filters: [],//过滤条件
            tempForm: {},//下拉框的数据
            filterQuery: '',//过滤条件的值
            filterQueryHeader: '',//过滤条件的key
            state: '',
            tableData: [],
            tempTableData: [],
            maxTotal: 0,
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            pageHeight: 0, // 初始化页面高度
            input: '',
            ArrVisible:[],
            viewContent: '',
            editDialogVisible: false,
            value2: '',
            form: {},
            formLabelWidth: '105px',
            filterTableList: '', //筛选后的数据
            form1: {
                "申请号": "applicationNumber",
                "专利名称": "title",
                "摘要": "abstract",
                "发明人": "inventor",
                "申请日期": "applicationDate",
                "酶": "enzyme",
                "EC号": "ec",
                "底物": "substrate",
                "底物标签": "substrateLabel",
                "页数": "substratePagesNumber",
                "产物": "product",
                "信息是否核对": "verification",
                "氨基酸序列": "aminoAcidSeq",
                "碱基对序列": "basePairSeq",
                "序列标签": "seqLabel",
                "反应条件": "reactionDescription",
                "反应页数": "reactionPagesNumber",
                "通式": "generalFormula",
                "PDF文件名": "pdfName",
            },
            textareaFields: ["申请号", "专利名称", '摘要', 'PDF文件名'], // 假设这些字段使用textarea
            onlyFilter: ['底物'],//用于控制哪些字段只能单个查询

        }
    },
    created() {
        this.pageHeight = this.getPageHeight();
        setTimeout(() => {
            this.loading = false;
        }, 500);

        this.$message({
            message: '请耐心等待数据加载',
            type: 'info'
        });


        this.selectInit();  // 初始化下拉框的数据
        this.$axios.get('/EnzymeInformationCheck' + '?state=').then((response) => {
            // 对response.data.data添加一个属性id，并将id的值设置成fasle
            
            for (const item of response.data.data) {
                item['id'] = response.data.data.indexOf(item);
                this.ArrVisible[item.id] = false;
            }
            this.tableData = response.data.data;
            this.maxTotal = response.data.total;
            this.tempTableData = response.data.data;
        })


    },
    watch: {
        // 监控 filterQueryHeader 的值
        filterQueryHeader(newVal, oldVal) {

            switch (newVal) {
                case 'substrate':
                    this.tishi = '底物只支持单个查询'
                    break;

                case 'applicationDate':
                    this.tishi = '请输入日期,例如 2001-01-01，范围日期请多次添加条件'
                    break;
                default:
                    break;
            }

            console.log('filterQueryHeader changed from', oldVal, 'to', newVal);

        }
    },
    methods: {

        selectInit() {
            // 初始化下拉框的数据

            // 将form1的key和value互换

            function invertObject(obj, excludeKeys = []) {
                return Object.keys(obj).reduce((acc, key) => {
                    // 检查当前键是否在排除列表中
                    if (excludeKeys.includes(key)) {
                        acc[obj[key]] = key;
                    }
                    return acc;
                }, {});
            }
            this.tempForm = invertObject(this.form1, ["酶", "申请号", "发明人", "申请日期", "EC号", "底物", "底物标签", "产物", "信息是否核对"]);
        },

        getKeyByValue(obj, value) {
            return Object.keys(obj).find(key => obj[key] === value);
        },
        addFilter() {
            if (this.filterQuery && this.filterQueryHeader) {
                // 获取当前键名
                const currentKey = this.tempForm[this.filterQueryHeader];


                // 查找数组中是否存在该键
                const existingFilterIndex = this.filters.findIndex(filter => filter.key === currentKey);
                // 去除掉前后空格
                this.filterQuery = this.filterQuery.replace(/(^\s*)|(\s*$)/g, "");

                if (existingFilterIndex !== -1 && this.onlyFilter.includes(currentKey)) {
                    // 如果找到了相应的键，并且键名为 'sub'，则更新其值
                    this.filters[existingFilterIndex].value = this.filterQuery;
                } else {
                    // 如果没有找到或键名不是 'sub'，则添加新的过滤器对象
                    this.filters.push({
                        key: currentKey,
                        value: this.filterQuery,
                    });
                }

                // 清空输入框
                this.filterQuery = '';
            }
        },
        removeFilter(index) {
            this.filters.splice(index, 1);
            if (this.filters.length == 0) {
              this.tableData = this.tempTableData;
            }
        },
        filterData() {
            // 在这里执行筛选数据的逻辑，可能需要调用API或更新视图
            console.log('筛选数据', this.filters);
            // 初始化一个对象来存储每个筛选键对应的一个或多个筛选值
            const filterMap = {};
            this.filters.forEach(filter => {
                // 获取每个筛选条件的键，通过form1对象转换得到实际的数据键
                const key = this.form1[filter.key];
                console.log('key:', key);
                // 如果filterMap中尚未有这个键，初始化一个空数组
                if (!filterMap[key]) {
                    filterMap[key] = [];
                }
                // 将筛选值转换为小写并添加到对应键的数组中
                filterMap[key].push(filter.value.toLowerCase());
            });
            // 使用filter方法筛选tableData中的行，保留满足所有筛选条件的行
            this.tableData = this.tempTableData.filter(row => {
                // 对filterMap中的每个键进行迭代
                return Object.keys(filterMap).every(key => {
                    // 检查当前行的该键的值是否满足某个筛选条件（'或'关系）
                    // 即，只要行的该键的值匹配filterMap中该键对应的任一值，就返回true
                    return filterMap[key].some(filterValue => {
                        // 将行的值转换为小写，并检查是否包含筛选值
                        return String(row[key]).toLowerCase().includes(filterValue);
                    });
                });
            });

        },
        clearFilter() {
            // 清除筛选条件的逻辑
            this.filters = [];
            this.tableData = this.tempTableData;
            console.log('清除筛选条件');
        },

        PointUpdate(row) {

            this.$confirm('是否标记为重点复核', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '保存',
                cancelButtonText: '初始化标记'
            }).then(() => {
                this.$message({
                    type: 'info',
                    message: '保存修改'
                });
                // 发送请求 
                this.$axios.post("/EnzymeInformationCheck/updateState", {
                    applicationNumber: row.applicationNumber,
                    state: '重点复核'
                }).then((response) => {

                    if (response.data.status == "200") {
                        this.$message({
                            message: response.data.msg,
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: '修改失败',
                            type: 'error'
                        });
                    }
                })

            })
                .catch(action => {
                    if (action === 'cancel') {

                        this.$axios.post("/EnzymeInformationCheck/updateState", {
                            applicationNumber: row.applicationNumber,
                            state: ''
                        }).then((response) => {
                            if (response.data.status == "200") {
                                this.$message({
                                    message: "初始化成功",
                                    type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: '修改失败',
                                    type: 'error'
                                });
                            }
                        })
                    }
                    this.$message({
                        type: 'info',
                        message: action === 'cancel'
                            ? '初始化成功'
                            : '关闭'
                    })
                });

        },
        

        update(row) {
            this.$confirm('是否标记为初步复核', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '保存',
                cancelButtonText: '初始化标记'
            })
                .then(() => {
                    this.$message({
                        type: 'info',
                        message: '保存修改'
                    });
                    // 发送请求 
                    this.$axios.post("/EnzymeInformationCheck/updateState", {
                        applicationNumber: row.applicationNumber,
                        state: '初步复核'
                    }).then((response) => {

                        if (response.data.status == "200") {
                            this.$message({
                                message: response.data.msg,
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: '修改失败',
                                type: 'error'
                            });
                        }
                    })

                })
                .catch(action => {
                    if (action === 'cancel') {
                        this.$axios.post("/EnzymeInformationCheck/updateState", {
                            applicationNumber: row.applicationNumber,
                            state: ''
                        }).then((response) => {
                            if (response.data.status == "200") {
                                this.$message({
                                    message: "初始化成功",
                                    type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: '修改失败',
                                    type: 'error'
                                });
                            }
                        })
                    }
                    this.$message({
                        type: 'info',
                        message: action === 'cancel'
                            ? '初始化成功'
                            : '关闭'
                    })
                });
        },
        isTextarea(fieldLabel) {
            return this.textareaFields.includes(fieldLabel);
        },
        getPageHeight() {
            return (window.innerHeight || document.documentElement.scrollHeight || document.body.scrollHeight) / 10 * 9.2 - 200;
        },
        view(row) {
            this.viewContent = JSON.stringify(row);
        },
        edit(row) {
            this.form = row;
            this.$set(this.ArrVisible, row.id, true);
        },
        cancle(row){
            this.$set(this.ArrVisible, row.id, false);
        },
        sbummit() {
            this.editDialogVisible = false;
            axios.post('/EnzymeInformationCheck/update', this.form, {
                headers: {
                    'token': localStorage.token
                }
            }).then((response) => {
                if (response.status == "200") {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: '修改失败',
                        type: 'error'
                    });
                }
            })

        },
        cle() {
            this.$message({
                message: '请耐心等待数据加载',
                type: 'info'
            });
            this.state = '';
            this.$axios.get('/EnzymeInformationCheck' + '?state=').then((response) => {
                this.tableData = response.data.data;
                this.maxTotal = response.data.total;
                this.tempTableData = response.data.data;
            })
        },
        review() {
            this.$message({
                message: '请耐心等待数据加载',
                type: 'info'
            });
            this.state = '重点复核';
            this.$axios.get('/EnzymeInformationCheck?state=' + this.state ).then((response) => {
                this.tableData = response.data.data;
                this.maxTotal = response.data.total;
                this.tempTableData = response.data.data;

            })
        },
        preliminary() {
            this.$message({
                message: '请耐心等待数据加载',
                type: 'info'
            });
            this.state = '初步复核';
            this.$axios.get('/EnzymeInformationCheck?state=' + this.state ).then((response) => {
                this.tableData = response.data.data;
                this.maxTotal = response.data.total;              

                this.tempTableData = response.data.data;

            })

        },
        handleCurrentChange(page) {
            this.tableData = [];
            this.$axios.get('/EnzymeInformationCheck' + '?pageNum=' + page + '&pageSize=20' + '&state' + this.state)
                .then((response) => {
                    this.tableData = response.data.data;
                }
                )

        }

    },
    computed: {
        // 使用计算属性动态获取页面高度
        computedPageHeight() {
            return this.getPageHeight();
        }
    },
    mounted() {
        // 组件挂载时计算页面高度
        this.pageHeight = this.getPageHeight();
        // 可以添加事件监听器来实时更新页面高度
        window.addEventListener('resize', () => {
            this.pageHeight = this.getPageHeight();
        });
    },
    beforeDestroy() {
        // 组件销毁前移除事件监听器
        window.removeEventListener('resize', this.getPageHeight);
    }

}
</script>

<style scoped>
.text-ellipsis {
    display: inline-block;
    max-width: 150px;
    /* 可以根据需要调整宽度 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* 距离下方 5px */
    margin-bottom: 5px;

}

.input-with-select {
    width: 40%;
}
</style>